import * as React from 'react';
import {useState , useEffect , useRef} from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { DataGrid ,GridActionsCellItem } from '@mui/x-data-grid';
import autoprefixer from 'autoprefixer';
import {useGetActiveOrgValueMutation,useGetActiveStateValueMutation} from "../../../services/adminLogIn";
import Link from '@mui/material/Link';
import useOrgHook from "./OrgCusHook";

const EnhancedTable= (props)=>{
  const { hasFocus, value } = props;

    
  const buttonElement = React.useRef(null);
  const rippleRef = React.useRef(null);
  const RenderDate = (params) => {
    React.useLayoutEffect(() => {
      if (hasFocus) {
        const input = buttonElement.current?.querySelector('input');
        input?.focus();
      } else if (rippleRef.current) {
        // Only available in @mui/material v5.4.1 or later
        rippleRef.current.stop({});
      }
    }, [hasFocus]);
  
    return (
      <strong>
        {/* {value?.getFullYear() ?? ''} */}
        <Link
          // component="button"
          ref={buttonElement}
          touchRippleRef={rippleRef}
        
          style={{ marginLeft: 16 }}
          // Remove button from tab sequence when cell does not have focus
          tabIndex={hasFocus ? 0 : -1}
          onClick={() => {
            console.log(params.row)
            let data = {}
              data.is_update = true
              data.name = params.row.name
              data.id = params.row.id
              data.address = params.row.address
              data.postalCode = params.row.address[0].postalCode
              data.city = params.row.address[0].city
              data.state = {
                name: params.row.address[0].state,
                isUt: false
              }
              data.city = {
                name: params.row.address[0].city,
                stateName: params.row.address[0].state,
              }
              data.phoneNumber = params.row.telecom[0].value
              data.emailId = params.row.telecom[1].value
              data.partOf = params.row.partOf.reference
              let data1 = {}
              data1.id = params.row.partOf && params.row.partOf!=null && params.row.partOf.getreference && params.row.partOf.getreference 
              data1.name = params.row.partOf && params.row.partOf!=null && params.row.partOf.identifier && params.row.partOf.identifier.value 
              props.setDropOrgState({...data1})
            props.setOrgTextFieldState({...data})
              props.showFormActions()
              
            // }
          }}
        >
          {params.row.name}
        </Link>
      </strong>
    );
  };
  
  RenderDate.propTypes = {
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    // value: getValue()
  };

  const columns = [
  // { field: 'id', headerName: 'ID', width: 70 },
  // { field: 'name', headerName: 'Organisation Name', width: 230 },

  {
    field: 'name',
    headerName: 'Organisation Name',
    width: 230,
    renderCell: RenderDate,
   
  },
  {
    field: 'partOf',
    headerName: 'Part Of ',
    width: 230,
    valueGetter: (params) =>
      `${params.row.partOf.reference || ''}`,
      
  },
];


const [data, setData] = useState([]);
useEffect(()=>{
  setData(props.content)
  // getActiveValFun();
},[])

  return (
    <div className='tablemanage'>
     {props && props.content && props.content.length>0 &&
      <DataGrid
        rows={props.content && props.content}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
      />
     }
    </div>
    
  );

}

export default EnhancedTable
