import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import logo from "../../../images/logo.svg";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardContent, Divider, Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseIcon from "@mui/icons-material/Close";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import useHeaderLogic from "./headerlogic";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import Typography from "@mui/material/Typography";
import "./header.css";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  NavLink,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  decryptAdminId,
  getTokensFromStorage,
} from "../../../globalFunctions/getLocalStorageData";
import {
  useAdminLogoutMutation,
  useGetResetTokenMutation,
  useGetUserByUserIdMutation,
  useUpdatingUserMutation,
} from "../../../services/adminLogIn";
import ErrorBoundary from "../../../logger/errorboundrycomp";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Header = (props) => {
  const {
    drawerState,
    setDrawerState,
    open,
    drawerStyleForOpen,
    openDrawer,
    handleClick,
    handleClose,
    menuPropFun,
  } = useHeaderLogic();

  const [auth, setAuth] = useState(true);
  const [getRefreshToken] = useGetResetTokenMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [updateUser] = useUpdatingUserMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [adminDetails, setAdminDetails] = useState();
  const [sampleReqRecordsLoading, setSampleReqRecordsLoading] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState();
  const [photo, setPhoto] = useState();
  const [userDetails, setUserDetails] = useState();
  const [sizeError, setSizeError] = useState(false);
  const [isProfilePhotoAction, setIsProfilePhotoAction] = useState(false);
  const tok = getTokensFromStorage();
  const navigate = useNavigate();
  // const handleChange = (event) => {
  //   setAuth(event.target.checked);
  // };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.getState(drawerState);
  }, [drawerState]);
  useEffect(() => {
    if (
      tok &&
      tok[0] &&
      JSON.parse(atob(tok[0].split(".")[1])).exp * 1000 < Date.now()
    ) {
      if (sessionStorage.getItem("resetTokenFlag") == "false") {
        console.log("sandeep");
        sessionStorage.setItem("resetTokenFlag", true);
        callRefreshTokenApi();
      }
    }
  }, [Date.now()]);

  const callRefreshTokenApi = async () => {
    const res = await getRefreshToken(tok[1]);
    if (res?.data?.accessToken) {
      sessionStorage.setItem("zExT1_a", res?.data?.accessToken);
      sessionStorage.setItem("e_Aophg", res?.data?.refreshToken);
      sessionStorage.setItem("resetTokenFlag", false);
    } else {
      navigate("/");
    }
  };

  const handleActionCancel = () => {
    setIsProfilePhotoAction(false);
    setPhoto("");
    closeProfileModal();
  };

  const closeProfileModal = () => {
    setModalOpen(false);
  };

  const getUserDetails = async () => {
    const res = await getUserByUserId(adminDetails.sub);
    if (res?.data?.content) {
      setUserDetails(res.data.content[0]);
      setProfilePhoto(res.data.content[0].profilePhoto);
    }
  };

  const handleUserIdClick = async () => {
    setModalOpen(true);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onPhotoChange = async (e, field) => {
    const file = e.target.files[0];
    if (file.size > 1048576) {
      setSizeError(true);
      setIsProfilePhotoAction(false);
    } else {
      setSizeError(false);
      const base64 = await convertToBase64(file);
      setPhoto(base64.split(",")[1]);
      setIsProfilePhotoAction(true);
    }
  };

  const removeProfilePhoto = async () => {
    const payload = { ...userDetails, profilePhoto: "" };
    const res = await updateUser(payload);
  };

  const uploadProfilePhoto = async () => {
    setSampleReqRecordsLoading(true)
    const payload = { ...userDetails, profilePhoto: photo };
    const res = await updateUser(payload);
    closeProfileModal();
    setIsProfilePhotoAction(false);
    if (res.data.statusCode === 20000) {
      setProfilePhoto(photo);
    }
    setSampleReqRecordsLoading(false)
  };

  useEffect(() => {
    setAdminDetails(tok && tok[0] && JSON.parse(atob(tok[0].split(".")[1])));
  }, []);

  useEffect(() => {
    if (adminDetails?.sub) {
      getUserDetails();
    }
  }, [adminDetails]);

  return (
    <>
      <ErrorBoundary>
        <header
          className={`h-12  w-full  transition-all duration-200 ease-linear shadow-md`}
          style={drawerState ? drawerStyleForOpen : {}}
        >
          <nav className="border   h-full ">
            <ul className="flex flex-row h-full">
              <li className="home-nav-li  basis-1/4 mainmenu">
                <ul className="flex row  h-full">
                  {/* <li className="iconContainer flex justify-center items-center w-3/12 ">
                            {
                                drawerState  ?  
                                
                                 
                                <IconButton size="medium" title="Close Drawer" style={{backgroundColor:'#f1f5f9'}} aria-label="delete"  onClick={openDrawer}>
                                    <CloseOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                   :
 
                                <IconButton size="medium" title="Open Drawer"  aria-label="delete"  onClick={openDrawer}>
                                    <MenuIcon fontSize="inherit" />
                                </IconButton>
                            }
                             

                                

                              </li> */}
                  <li className="LogoContainer w-2/4 flex justify-start  ml-6 items-center">
                    <figure>
                      <img
                        title="karkinos Logo"
                        className="h-full w-full ml-3"
                        src={logo}
                        alt="Karnikos logo"
                      />
                    </figure>
                  </li>
                </ul>
              </li>
              <li className="home-nav-li  h-full  flex justify-start grow-2 basis-1/2 items-center ">
                <div className="searchContainer border border-solid border-sideBorderColor  ml-10 flex justify-start items-center bg-white w-11/12 h-9 p-3 pb-4 rounded-searchRadius">
                  <div className="searchIconContainer ">
                    <SearchOutlinedIcon style={{ fontSize: "20px" }} />
                  </div>
                </div>
              </li>

              {auth && (
                <div className="curseridmain mt-1">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    {profilePhoto ? (
                      <div className=" profile1">
                        <Avatar
                          alt="Remy Sharp"
                          className="Avatar"
                          src={`data:image/png;base64,${profilePhoto}`}
                          sx={{ width: 42, height: 42 }}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center pl-0.5 profile bg-iconColor rounded-full items-center">
                        <strong style={{ color: "white", fontSize: "18px" }}>
                          {adminDetails?.name?.split("")[0].toUpperCase()}
                        </strong>
                        <strong style={{ color: "white", fontSize: "18px" }}>
                          {adminDetails?.family_name
                            ?.split("")[0]
                            .toUpperCase()}
                        </strong>
                      </div>
                    )}
                  </IconButton>
                  <div>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseButton}
                      PaperProps={{
                        style: {
                          width: 355,
                          marginTop: 32,
                        },
                      }}
                    >
                      <MenuItem onClick={handleCloseButton}>Account</MenuItem>

                      {profilePhoto ? (
                        <div
                          className="flex justify-center items-center rounded-full curserid pl-8 mt-2"
                          onClick={handleUserIdClick}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            className="Avatar"
                            src={`data:image/png;base64,${profilePhoto}`}
                            sx={{ width: 60, height: 60 }}
                          />
                        </div>
                      ) : (
                        <div
                          className="flex justify-center items-center h-11 w-11 rounded-full bg-iconColor curserid ml-2"
                          onClick={handleUserIdClick}
                        >
                          <>
                            <strong
                              style={{ color: "white", fontSize: "18px" }}
                            >
                              {adminDetails?.name?.split("")[0].toUpperCase()}
                            </strong>
                            <strong
                              style={{ color: "white", fontSize: "18px" }}
                            >
                              {adminDetails?.family_name
                                ?.split("")[0]
                                .toUpperCase()}
                            </strong>
                          </>
                        </div>
                      )}

                      <div className="profileIconContainer mt-1 h-full w-2/3">
                        <h2 style={{ fontSize: "20px" }}>
                          {adminDetails &&
                            adminDetails?.name
                              ?.charAt(0)
                              .toUpperCase()
                              .concat(
                                adminDetails &&
                                adminDetails?.name?.split(" ")[0].slice(1)
                              )}{" "}
                          {adminDetails &&
                            adminDetails?.name
                              ?.split(" ")[1]
                              .charAt(0)
                              .toUpperCase()
                              .concat(
                                adminDetails &&
                                adminDetails?.name?.split(" ")[1].slice(1)
                              )}
                        </h2>
                        <h2 className="font-medium">
                          {adminDetails &&
                            adminDetails.email &&
                            adminDetails.email}
                        </h2>
                        <p className="font-normal text-white text-sm">
                          {adminDetails &&
                            adminDetails.roles &&
                            adminDetails.roles[0]}
                        </p>
                      </div>

                      <MenuItem>
                        <ListItemIcon>
                          <NavLink
                            to="/dashboard/change-password"
                            style={(isActive) => {
                              return { color: `${isActive ? "red" : ""}` };
                            }}
                          >
                            <li className="flex pl-8 mb-5 cursor-pointer changepasswoedid">
                              {" "}
                              <LockOpenIcon style={{ color: "#0F4391" }} />{" "}
                              <span className="text-lg leading-snug font-medium pl-5 mt-1  text-iconColor">
                                Change Password
                              </span>{" "}
                            </li>
                          </NavLink>
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <NavLink to="/" onClick={() => { sessionStorage.clear() }}>
                            <li className="flex pl-8 mb-5 cursor-pointer logoutid ">
                              {" "}
                              <LogoutIcon style={{ color: "#0F4391" }} />{" "}
                              <span className="text-lg leading-snug font-medium  pl-5 text-iconColor">
                                Logout
                              </span>
                            </li>
                          </NavLink>
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              )}

              <li className="home-nav-li basis-1/4 flex justify-end items-center">
                {/* <Tooltip title="Account settings" style={{marginRight:'25px' , width:'40px' , height:'40px'}}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <MoreVertIcon></MoreVertIcon>
                        </IconButton>
                        </Tooltip>

                        <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={menuPropFun}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                   >
                      <MenuItem>
                        <Avatar /> Profile
                      </MenuItem>
                      <MenuItem>
                        <Avatar /> My account
                      </MenuItem>
                      <Divider />
                      <MenuItem>
                        <ListItemIcon>
                          <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Add another account
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu> */}
              </li>
            </ul>
          </nav>
          <Modal
            open={modalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {isProfilePhotoAction ? (
              <>
                {photo ? (
                  <Box sx={style} style={{ width: "420px", padding: "40px" }}>
                    <div>
                      <div
                        className="flex justify-center items-center h-11 w-11 rounded-full curserid"
                        style={{
                          height: "220px",
                          width: "220px",
                          left: "15%",
                        }}
                      >
                        <Avatar
                          className="headerimp"
                          alt="Remy Sharp"
                          src={`data:image/png;base64,${photo}`}
                          sx={{ width: 220, height: 220 }}
                        />
                      </div>

                      <div className="flex justify-center items-center">
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "auto",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                          onClick={handleActionCancel}
                        >
                          Cancel
                        </Button>
                        <div>
                          {sampleReqRecordsLoading ? <CircularProgress /> : ""}
                        </div>
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "auto",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                          onClick={uploadProfilePhoto}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Box>
                ) : (
                  <Box sx={style} style={{ width: "420px", padding: "40px" }}>
                    <div>
                      <div
                        className="flex justify-center items-center h-11 w-11 rounded-full bg-iconColor curserid"
                        style={{ height: "220px", width: "220px", left: "20%" }}
                      >
                        <strong style={{ color: "white", fontSize: "60px" }}>
                          {adminDetails?.name?.split("")[0].toUpperCase()}
                        </strong>
                        <strong style={{ color: "white", fontSize: "60px" }}>
                          {adminDetails?.family_name
                            ?.split("")[0]
                            .toUpperCase()}
                        </strong>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <h2>Remove Profile Picture?</h2>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "auto",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                          onClick={handleActionCancel}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "auto",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                          onClick={removeProfilePhoto}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </Box>
                )}
              </>
            ) : (
              <>
                {profilePhoto ? (
                  <Box sx={style} style={{ width: "460px", padding: "40px" }}>
                    <div>
                      <CloseIcon onClick={handleActionCancel} />
                      <div
                        className="flex justify-center items-center h-11 w-11 rounded-full curserid"
                        style={{ height: "220px", width: "220px", left: "15%" }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          className="Avatar"
                          src={`data:image/png;base64,${profilePhoto}`}
                          sx={{ width: 220, height: 220 }}
                        />

                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "auto",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                        >
                          Update Profile Picture
                          <input
                            type="file"
                            hidden
                            name="photo"
                            id="file"
                            accept=".jpg, .jpeg, .png"
                            data-testid="photo"
                            onChange={(e) => onPhotoChange(e, "photo")}
                          />
                        </Button>

                        {/* <Button
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "auto",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                          onClick={() => setIsProfilePhotoAction(true)}
                        >
                          <DeleteIcon />
                          Remove
                        </Button> */}
                      </div>
                    </div>
                  </Box>
                ) : (
                  <Box sx={style} style={{ width: "460px", padding: "40px" }}>
                    <div>
                      <div
                        className="flex justify-center items-center h-11 w-11 rounded-full bg-iconColor curserid"
                        style={{ height: "220px", width: "220px", left: "15%" }}
                      >
                        <strong style={{ color: "white", fontSize: "100px" }}>
                          {adminDetails?.name?.split("")[0].toUpperCase()}
                        </strong>
                        <strong style={{ color: "white", fontSize: "100px" }}>
                          {adminDetails?.family_name
                            ?.split("")[0]
                            .toUpperCase()}
                        </strong>
                      </div>
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          marginRight: "10px",
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "auto",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        Upload Profile Picture
                        <input
                          type="file"
                          hidden
                          name="photo"
                          id="file"
                          accept=".jpg, .jpeg, .png"
                          data-testid="photo"
                          onChange={(e) => onPhotoChange(e, "photo")}
                        />
                      </Button>

                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          marginRight: "10px",
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "auto",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                        onClick={closeProfileModal}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                )}
                {sizeError ? <div>Size should be less than 1MB</div> : null}
              </>
            )}
          </Modal>
        </header>
      </ErrorBoundary>
    </>
  );
};

export default Header;
