import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { Grid, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Delete from "../../global/icons/delete.png";
import { Button } from "react-scroll";
import {
    useGetEproByUserIdMutation,
    useGetEproQuestionnaireMutation,
    useUpdateEprobyUserIdMutation,
} from "../../../services/adminLogIn";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";


const EProPlanForm = (props) => {
    const { planId } = useParams(); // Get the planId from the URL params
    const navigate = useNavigate();
    const [getEproByUserId] = useGetEproByUserIdMutation();
    const [getEproQuestionnaire] = useGetEproQuestionnaireMutation();
    const [updateEprobyUserId] = useUpdateEprobyUserIdMutation();
    const isEditing = Boolean(planId);
    const [eproQuestionnaires, setEproQuestionnaires] = useState([
        {
            eproQuestionnaireMasterId: "",
            eproQuestionnaireName: "",
            eproQuestionnaireDisplayName: "",
        },
    ]);

    const [errors, setErrors] = useState({
        eproPlanMasterName: "",
        description: "",
        status: "",
        eproPlanTemplates: [],
    });

    const [planData, setPlanData] = useState({
        eproPlanMasterName: "",
        description: "",
        status: "ACTIVE",
        dmg: "dmg",
        eproPlanTemplates: [],
    });
    const [loading, setLoading] = useState(false);

    const getEproData = async () => {
        if (!planId) {
            setPlanData({
                eproPlanMasterName: "",
                description: "",
                status: "ACTIVE",
                dmg: "dmg",
                eproPlanTemplates: [],
            });
            return;
        }

        setLoading(true); // Start loading
        try {
            const res = await getEproByUserId({ id: planId });

            if (res?.data) {
                setPlanData({
                    eproPlanMasterId: res.data.eproPlanMasterId,
                    eproPlanMasterName: res.data.eproPlanMasterName || "",
                    description: res.data.description || "",
                    status: res.data.status,
                    dmg: "dmg",
                    eproPlanTemplates: res.data.eproPlanTemplates.map((template) => ({
                        ...template,
                        sequenceNumber: template.sequenceNumber,
                        recurrence: template.recurrence,
                        eproQuestionnaireMasterId: template.eproQuestionnaireMasterId,
                        occurrenceType: template.occurrenceType,
                        occurrenceInterval: template.occurrenceInterval,
                        occurrenceIntervalUnit: template.occurrenceIntervalUnit,
                    })),
                });
            } else {
                toasterFunction('The ' + planId + ' plan no longer exists', "error");
                setTimeout(() => navigate(-1), 4000);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toasterFunction("Failed to fetch data", "error");
        } finally {
            setLoading(false); // Stop loading
        }
    };


    const getQuestionnaire = async () => {
        try {
            const res = await getEproQuestionnaire();

            if (res && Array.isArray(res.data)) {
                const activeQuestionnaires = res.data.filter(item => item.status === 'ACTIVE');
                const formattedData = activeQuestionnaires.map((item) => {
                    let localeName = "";
                    switch (item?.locale) {
                        case "en_US":
                            localeName = "English";
                            break;
                        case "hi":
                            localeName = "Hindi";
                            break;
                        case "ru":
                            localeName = "Malayalam";
                            break;
                        default:
                            localeName = item?.locale || " ";
                            break;
                    }

                    return {
                        eproQuestionnaireMasterId: item?.eproQuestionnaireMasterId || "",
                        eproQuestionnaireName: item?.eproQuestionnaireName || "Unnamed",
                        eproQuestionnaireDisplayName:
                            (item?.eproQuestionnaireDisplayName ? `${item?.eproQuestionnaireDisplayName} - ` : "") +
                            `${localeName} - ${item?.version || "No Version"}`,
                    };
                });
                setEproQuestionnaires(formattedData);
            } else {
                // Handle case where data is missing or not an array
                console.error("Invalid data format:", res);
                toasterFunction("Error while Fetching Questionnaire", "error");
                setEproQuestionnaires([
                    {
                        eproQuestionnaireMasterId: "",
                        eproQuestionnaireName: "",
                        eproQuestionnaireDisplayName: "",
                    },
                ]); // Fallback to empty array if data is invalid
            }
        } catch (error) {
            // Handle any other errors (e.g., network errors)
            console.error("Failed to fetch questionnaires:", error);
            toasterFunction("Error while Fetching Questionnaire", "error");
            setEproQuestionnaires([
                {
                    eproQuestionnaireMasterId: "",
                    eproQuestionnaireName: "",
                    eproQuestionnaireDisplayName: "",
                },
            ]); // Set to empty array in case of failure
        }
    };

    useEffect(() => {
        getEproData();
        getQuestionnaire();
    }, [planId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlanData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleTemplateChange = (index, field, value) => {
        const newTemplates = [...planData.eproPlanTemplates];
        const newErrors = [...errors.eproPlanTemplates];
        if (!newErrors[index]) {
            newErrors[index] = [];
        }
        if (value === "Select") {
            newErrors[index][field] = "Please select a valid option";
        } else {
            newErrors[index][field] = ""; // Clear error if valid
        }
        newTemplates[index][field] = value;
        setPlanData({ ...planData, eproPlanTemplates: newTemplates });
        setErrors((prevErrors) => ({
            ...prevErrors,
            eproPlanTemplates: newErrors
        }));
    };

    const addRow = () => {
        setPlanData((prevData) => ({
            ...prevData,
            eproPlanTemplates: [
                ...prevData.eproPlanTemplates,
                {
                    sequenceNumber: (prevData.eproPlanTemplates?.length || 0) + 1,
                    occurrenceType: "",
                    occurrenceInterval: "",
                    occurrenceIntervalUnit: "",
                    eproPlanMasterId: planData.eproPlanMasterId,
                    recurrence: 1,
                },
            ],
        }));
    };

    const addRowBetween = (index) => {
        const newTemplates = [...planData.eproPlanTemplates];
        const newRow = {
            sequenceNumber: (planData.eproPlanTemplates?.length || 0) + 1,
            occurrenceType: "",
            occurrenceInterval: "",
            occurrenceIntervalUnit: "",
            eproPlanMasterId: planData.eproPlanMasterId,
            recurrence: 1,
        };
        newTemplates.splice(index + 1, 0, newRow);
        newTemplates.forEach((item, i) => {
            item.sequenceNumber = i + 1;
        });
        setPlanData({ ...planData, eproPlanTemplates: newTemplates });

    };

    const deleteRow = (index) => {
        const newTemplates = planData.eproPlanTemplates.filter(
            (_, i) => i !== index
        );
        newTemplates.forEach((item, i) => {
            item.sequenceNumber = i + 1;
        });
        setPlanData({ ...planData, eproPlanTemplates: newTemplates });
        toasterFunction("Row Deleted Successfully", "success");
    };

    const handleCancel = () => {
        navigate("/dashboard/epro");
    };
    const handleSave = async () => {
        let validationErrors = {};

        // Validate eproPlanMasterName
        if (!planData.eproPlanMasterName) {
            validationErrors.eproPlanMasterName = "ePRO Plan Name is required";
        }

        // Validate description
        if (!planData.description) {
            validationErrors.description = "ePRO Plan Description is required";
        }

        // Validate status
        if (!planData.status) {
            validationErrors.status = "Status is required";
        }

        // Validate templates array
        if (!planData.eproPlanTemplates || planData.eproPlanTemplates.length === 0) {
            validationErrors.eproPlanTemplates = ["At least one template is required."];
        } else {
            const templateErrors = planData.eproPlanTemplates.map((template, index) => {
                let templateError = {};
                if (!template.eproQuestionnaireMasterId || template.eproQuestionnaireMasterId === "Select") {
                    templateError.eproQuestionnaireMasterId = `Questionnaire is required at row ${index + 1}`;
                }
                if (!template.occurrenceType || template.occurrenceType === "Select") {
                    templateError.occurrenceType = `Occurrence Type is required at row ${index + 1}`;
                }
                if (!template.occurrenceInterval || template.occurrenceInterval === "Select") {
                    templateError.occurrenceInterval = `Occurrence Interval is required at row ${index + 1}`;
                }
                if (!template.occurrenceIntervalUnit || template.occurrenceIntervalUnit === "Select") {
                    templateError.occurrenceIntervalUnit = `Occurrence Interval Unit is required at row ${index + 1}`;
                }
                return Object.keys(templateError).length > 0 ? templateError : null;
            });

            // Filter out valid templates and store errors
            const filteredTemplateErrors = templateErrors.filter((err) => err !== null);
            if (filteredTemplateErrors.length > 0) {
                validationErrors.eproPlanTemplates = filteredTemplateErrors;
            }
        }

        console.log("Validation Errors:", validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const res = await updateEprobyUserId(planData);
            console.log("API Response:", res);

            if (res?.error?.data?.message) {
                toasterFunction(res.error.data.message, "error");
            } else {
                toasterFunction("ePRO Plan Updated Successfully", "success");
                navigate("/dashboard/epro");
            }
        } catch (error) {
            console.error("API call failed:", error);
            toasterFunction("An unexpected error occurred.", "error");
        }
    };


    return (
        <div className={props.expand1.empty === true ? "main1" : "main"}>
            <Breadcrums
                firsttab={"Dashboard"}
                secondtab="Categories"
                thirdtab="ePRO Plans"
            />
            <ToastContainer />
            <Grid container mt={-2}>
                <Grid item className="leftcontainer1 rounded-lg ">
                    <Grid className="container items-center top-8 w-full rounded-radiusFourPix">
                        <div className="container mx-auto p-4 scrolldown">
                            <h2 className="text-2xl mb-4 m-r-f">ePRO Plan Overview</h2>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col">
                                    <label className="text-sm m-r-f font-light mb-2">
                                        ePRO Plan Name
                                    </label>
                                    <input
                                        name="eproPlanMasterName"
                                        className="border-b m-r-f font-light pt-2  mb-4 w-60"
                                        type="text"
                                        placeholder="Input Text"
                                        value={planData.eproPlanMasterName || ""}
                                        onChange={handleChange}
                                        disabled={planId || loading}
                                    />
                                    {errors.eproPlanMasterName && (
                                        <span className="text-red-500 text-xs">
                                            {errors.eproPlanMasterName}
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm m-r-f font-light mb-2">
                                        ePRO Plan Description
                                    </label>
                                    <input
                                        name="description"
                                        className="border-b m-r-f font-light pt-2 mb-4 w-60"
                                        type="text"
                                        placeholder="Input Text"
                                        value={planData.description || ""}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                    {errors.description && (
                                        <span className="text-red-500 text-xs">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm m-r-f font-light mb-2">
                                        Status
                                    </label>

                                    {planId || loading ? (<>
                                        <select
                                            name="status"
                                            className="border p-2 m-r-f font-light  rounded-md "
                                            value={planData.status || ""}
                                            onChange={handleChange}
                                        >
                                            <option className="m-r-f font-light" value="ACTIVE">
                                                ACTIVE
                                            </option>
                                            <option value="INACTIVE" className="m-r-f font-light">
                                                INACTIVE
                                            </option>
                                        </select></>) : (<>
                                            <select
                                                name="status"
                                                className="border p-2 m-r-f font-light w-full rounded-md"
                                                value={planData.status || ""}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option className="m-r-f font-light" value="ACTIVE">
                                                    ACTIVE
                                                </option>
                                                <option value="INACTIVE" className="m-r-f font-light">
                                                    INACTIVE
                                                </option>
                                            </select></>)}
                                </div>
                            </div>
                            <div className="overflow-scroll">
                                <div className="flex justify-between items-center pt-4 mb-4">
                                    <h3 className="text-lg m-r-f">ePRO Plan List</h3>
                                    <Button
                                        onClick={addRow}
                                        variant="contained"
                                        disableElevation
                                        style={{
                                            marginRight: "10px",
                                            borderColor: "#0F4391",
                                            width: "140px",
                                            height: "39px",
                                            borderRadius: "9999px",
                                            backgroundColor: "#E8FAFF",
                                            color: "#0F4391",
                                            border: "1px solid #0F4391",
                                        }}
                                    >
                                        Add new row
                                    </Button>
                                </div>
                                {planData.eproPlanTemplates.length > 0 ? (
                                    <div className="scrollit">
                                        <table className="table-auto w-full border-collapse">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 m-r-f">Order No.</th>
                                                    <th className="px-4 m-r-f">Questionnaire</th>
                                                    <th className="px-4 m-r-f">Time Scale</th>
                                                    <th className="px-4 m-r-f">Time Unit</th>
                                                    <th className="px-4 m-r-f">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {planData.eproPlanTemplates.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="px-4 text-center">
                                                            {item.sequenceNumber ? (
                                                                <span className="font-medium">{item.sequenceNumber}</span>
                                                            ) : (
                                                                <span className="text-gray-400 italic">Select</span>
                                                            )}
                                                        </td>

                                                        <td className="px-4">
                                                            <div className="flex flex-col">
                                                                <select
                                                                    disabled={loading}
                                                                    value={item.eproQuestionnaireMasterId || "Select"}
                                                                    onChange={(e) =>
                                                                        handleTemplateChange(index, "eproQuestionnaireMasterId", e.target.value)
                                                                    }
                                                                    className={`w-full border-b p-2 rounded-md ${errors.eproPlanTemplates &&
                                                                        errors.eproPlanTemplates[index]?.eproQuestionnaireMasterId
                                                                        ? "border-red-500"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <option value="Select" className="m-r-f font-light">Select</option>
                                                                    {eproQuestionnaires.length > 0 ? (
                                                                        eproQuestionnaires.map((item, idx) => (
                                                                            <option
                                                                                key={idx}
                                                                                value={item.eproQuestionnaireMasterId}
                                                                                className="m-r-f font-light"
                                                                            >
                                                                                {item.eproQuestionnaireDisplayName}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option disabled className="m-r-f font-light">No questionnaires available</option>
                                                                    )}
                                                                </select>

                                                                {/* Error message with ellipsis */}
                                                                {errors.eproPlanTemplates &&
                                                                    errors.eproPlanTemplates[index]?.eproQuestionnaireMasterId && (
                                                                        <div className="text-red-500 text-xs mt-1 h-5">
                                                                            <span
                                                                                title={errors.eproPlanTemplates[index]?.eproQuestionnaireMasterId} // Tooltip showing the full error
                                                                                className="truncate block"
                                                                            >
                                                                                {errors.eproPlanTemplates[index]?.eproQuestionnaireMasterId}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </td>

                                                        <td className="px-4">
                                                            <div className="flex space-x-4 flex-row">
                                                                {/* Occurrence Type Select */}
                                                                <div className="flex flex-col w-1/2">
                                                                    <select
                                                                        disabled={loading}
                                                                        value={item.occurrenceType || "Select"}
                                                                        onChange={(e) =>
                                                                            handleTemplateChange(index, "occurrenceType", e.target.value)
                                                                        }
                                                                        className={`w-full border-b p-2 rounded-md ${errors.eproPlanTemplates &&
                                                                            errors.eproPlanTemplates[index]?.occurrenceType
                                                                            ? "border-red-500"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        <option value="Select" className="m-r-f font-light">Select</option>
                                                                        <option value="PRE" className="m-r-f font-light">Pre</option>
                                                                        <option value="POST" className="m-r-f font-light">Post</option>
                                                                    </select>

                                                                    {/* Error message with ellipsis */}
                                                                    {errors.eproPlanTemplates &&
                                                                        errors.eproPlanTemplates[index]?.occurrenceType && (
                                                                            <div className="text-red-500 text-xs mt-1 h-5">
                                                                                <span
                                                                                    title={errors.eproPlanTemplates[index]?.occurrenceType} // Tooltip showing the full error
                                                                                    className="truncate block"
                                                                                >
                                                                                    {errors.eproPlanTemplates[index]?.occurrenceType}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                </div>

                                                                {/* Occurrence Interval Select */}
                                                                <div className="flex flex-col w-1/2">
                                                                    <select
                                                                        disabled={loading}
                                                                        value={item.occurrenceInterval || "Select"}
                                                                        onChange={(e) =>
                                                                            handleTemplateChange(index, "occurrenceInterval", e.target.value)
                                                                        }
                                                                        className={`w-full border-b p-2 rounded-md ${errors.eproPlanTemplates &&
                                                                            errors.eproPlanTemplates[index]?.occurrenceInterval
                                                                            ? "border-red-500"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        <option value="Select" className="m-r-f font-light">Select</option>
                                                                        {Array.from({ length: 31 }, (_, i) => i + 1).map((num) => (
                                                                            <option key={num} value={num} className="m-r-f font-light">
                                                                                {num}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    {/* Error message with ellipsis */}
                                                                    {errors.eproPlanTemplates &&
                                                                        errors.eproPlanTemplates[index]?.occurrenceInterval && (
                                                                            <div className="text-red-500 text-xs mt-1 h-5">
                                                                                <span
                                                                                    title={errors.eproPlanTemplates[index]?.occurrenceInterval} // Tooltip showing the full error
                                                                                    className="truncate block"
                                                                                >
                                                                                    {errors.eproPlanTemplates[index]?.occurrenceInterval}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td className="px-4">
                                                            <div className="flex flex-col">
                                                                <select
                                                                    disabled={loading}
                                                                    value={item.occurrenceIntervalUnit || "Select"}
                                                                    onChange={(e) =>
                                                                        handleTemplateChange(index, "occurrenceIntervalUnit", e.target.value)
                                                                    }
                                                                    className={`border-b p-2 rounded-md w-full ${errors.eproPlanTemplates &&
                                                                        errors.eproPlanTemplates[index]?.occurrenceIntervalUnit
                                                                        ? "border-red-500"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <option value="Select" className="m-r-f font-light">Select</option>
                                                                    <option value="DAY" className="m-r-f font-light">Days</option>
                                                                    <option value="WEEK" className="m-r-f font-light">Weeks</option>
                                                                    <option value="MONTH" className="m-r-f font-light">Months</option>
                                                                </select>

                                                                {/* Error message with ellipsis */}
                                                                {errors.eproPlanTemplates &&
                                                                    errors.eproPlanTemplates[index]?.occurrenceIntervalUnit && (
                                                                        <div className="text-red-500 text-xs mt-1 h-5">
                                                                            <span
                                                                                title={errors.eproPlanTemplates[index]?.occurrenceIntervalUnit} // Tooltip showing the full error
                                                                                className="truncate block"
                                                                            >
                                                                                {errors.eproPlanTemplates[index]?.occurrenceIntervalUnit}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </td>

                                                        {/* Column for action buttons */}
                                                        <td>
                                                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                                <IconButton sx={{ position: "static", zIndex: 1, pointerEvents: "auto" }} disabled={loading} onClick={() => deleteRow(index)}>
                                                                    <img className="h-8" src={Delete} alt="delete" />
                                                                </IconButton>
                                                                <IconButton sx={{ position: "static", zIndex: 1, pointerEvents: "auto" }} disabled={loading} onClick={() => addRowBetween(index)}>
                                                                    <AddCircleOutlineIcon />
                                                                </IconButton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                ) : addRow()}
                                <div className="mt-4 flex flex-row justify-end">
                                    <Button
                                        onClick={handleCancel}
                                        disableElevation
                                        disabled={loading}
                                        style={{
                                            marginRight: "10px",
                                            borderColor: "#0F4391",
                                            width: "140px",
                                            height: "39px",
                                            borderRadius: "9999px",
                                            backgroundColor: "#E8FAFF",
                                            color: "#0F4391",
                                            border: "1px solid #0F4391",
                                        }}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        onClick={handleSave}
                                        disableElevation
                                        disabled={loading}
                                        style={{
                                            marginRight: "10px",
                                            borderColor: "#0F4391",
                                            width: "140px",
                                            height: "39px",
                                            borderRadius: "9999px",
                                            backgroundColor: "#E8FAFF",
                                            color: "#0F4391",
                                            border: "1px solid #0F4391",
                                        }}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default EProPlanForm;