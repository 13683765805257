import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Autocomplete, Box, Grid, Modal, Typography } from "@mui/material";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
import { useNavigate } from "react-router-dom";
import { useGetEproListMutation } from "../../../services/adminLogIn";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { Button } from "react-scroll";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const Epro = (props) => {
    const [access, setAccess] = useState(
        JSON.parse(sessionStorage.getItem("access"))
    );
    const [items, setItems] = useState([]);
    const [searchVal, setsearchVal] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [isNewData, setIsNewData] = useState(true);
    const [getEproList] = useGetEproListMutation();
    const [isFormVisible, setFormVisible] = useState({
        isOpen: false,
        isClose: true,
    });
    const navigate = useNavigate();

    const [openFilterModal, setOpenFilterModal] = useState(false);
    const handleFilterModalOpen = () => {
        setOpenFilterModal(true);
    };


    const [paginationdata, setPaginationData] = useState({
        string: "",
        page: 0,
        status: "",
        size: 20,
    });
    const getListData = async (data, check) => {
        const res = await getEproList(data);
        if (res?.data?.content) {
            setItems(res?.data?.content);
        } else {
            if (check == "search") {
                toasterFunction("No ePRO Plan was found for the search.", "error");
                setItems([]);
                setOpenFilterModal(false);
                return;
            }
            toasterFunction("Error while Fetching Data", "error");
            setItems([]);
        }

        setOpenFilterModal(false);
    };
    const onSearchevent = (value) => {
        setsearchVal(value);
        getListData({
            string: value,
            page: 0,
            status: "",
            size: 20,
        }, "search");

        if (value === "") {
            setIsNewData(true);
            setCurrentPage(1);
        }
    };
    const filterStatusOptions = ["ACTIVE", "INACTIVE"];
    const handleFilterValueChange = (name, value) => {
        if (name === "status") {
            setPaginationData((prev) => ({ ...prev, [name]: value }));
        }
    };
    const handleFilterModalClose = () => {
        setOpenFilterModal(false);
    };
    const resetFilterData = () => {
        setPaginationData({
            string: '',
            status: "",
            page: 0,
            size: 20,
        });
        getListData({
            string: '',
            page: 0,
            status: "",
            size: 20,
        }, "");
    };
    useEffect(() => {
        // Get stored values
        const storedPaginationData = sessionStorage.getItem("paginationData");
        const storedSearchVal = sessionStorage.getItem("searchVal");
        const storedItems = sessionStorage.getItem("items");

        if (storedPaginationData) {
            setPaginationData(JSON.parse(storedPaginationData));
        }
        if (storedSearchVal) {
            setsearchVal(storedSearchVal);
        }
        if (storedItems) {
            setItems(JSON.parse(storedItems));
        }
    }, []);
    useEffect(() => {
        // Store the updated values
        sessionStorage.setItem("paginationData", JSON.stringify(paginationdata));
        sessionStorage.setItem("searchVal", searchVal);
        sessionStorage.setItem("items", JSON.stringify(items));
    }, [paginationdata, searchVal, items]);

    useEffect(() => {
        getListData(paginationdata, "");
        setPaginationData((prev) => ({ ...prev, "status": "" }));
        setsearchVal("");
    }, []);
    return (
        <div>
            <div className={props.expand1.empty === true ? "main1" : "main"}>
                <Breadcrums
                    firsttab={"Dashboard"}
                    secondtab="Categories"
                    thirdtab="ePRO Plans"
                ></Breadcrums>
                <ToastContainer />
                <Grid container mt={-2}>
                    <Grid item className="leftcontainer1 rounded-lg">
                        <Grid className="  container items-center justify-between w-10/12  flex flex-row top-8  rounded-radiusFourPix ">
                            <div className="relative m-4 w-64">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    {/* Search icon */}
                                    <SearchOutlinedIcon />
                                </span>
                                <input
                                    type="text"
                                    value={searchVal}
                                    onChange={(e) => onSearchevent(e.target.value)}
                                    onKeyDown={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                    }}
                                    placeholder="Search"
                                    className="pl-10 pr-4 py-2 border border-gray-300 rounded-full w-full focus:outline-none focus:border-blue-500"
                                />
                            </div>
                            <div className="flex flex-row items-center">
                                <Tooltip title={access?.locationadd ? "" : "No Access"} arrow>
                                    <Grid
                                        disabled={access?.useradd == false}
                                        onClick={() => {
                                            navigate(`/dashboard/epro/new`);
                                        }}
                                        className="addIcon"
                                    >
                                        <span className=" p-1 m-r-f ">Create</span>
                                        <AddCircleOutlineIcon
                                            style={{
                                                fontSize: "14px",
                                                color: "#545454",
                                                marginBottom: "3px",
                                                color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"
                                                    }`,
                                            }}
                                        />
                                    </Grid>
                                </Tooltip>
                                <Grid className="addIcon   " onClick={handleFilterModalOpen}>
                                    <div className="flex flex-row items-center">
                                        <span className="px-2 m-r-f">Filter</span>
                                        <img
                                            src={filter}
                                            alt="filter.png"
                                            className="  size-3"
                                        ></img>
                                    </div>
                                </Grid>
                                <Modal
                                    open={openFilterModal}
                                    // onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                        sx={style}
                                        style={{
                                            width: "450px",
                                            padding: "50px",
                                            borderColor: "white",
                                        }}
                                    >
                                        <Typography
                                            className="filtermain"
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            style={{
                                                fontFamily: "MontserratRegular",
                                                marginTop: "5px",
                                            }}
                                        >
                                            Filter by
                                        </Typography>


                                        <div className="my-5 OrgNameContainer ">
                                            <h3 className="font-normal text-o-form-label-color">
                                                Status
                                            </h3>
                                            <Autocomplete
                                                value={paginationdata.status}
                                                autoHighlight={true}
                                                disableClearable
                                                onChange={(e, v) =>
                                                    handleFilterValueChange("status", v)
                                                }
                                                getOptionLabel={(option) => (option ? option : "")}
                                                id="org-list-option"
                                                options={filterStatusOptions}
                                                sx={{ width: 360 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="standard"
                                                        autoComplete="OFF"
                                                        {...params}
                                                        label=""
                                                    />
                                                )}
                                            />
                                        </div>

                                        <Button
                                            variant="contained"
                                            disableElevation
                                            onClick={handleFilterModalClose}
                                            style={{
                                                marginRight: "10px",
                                                top: "30px",
                                                borderColor: "#0F4391",
                                                width: "99px",
                                                height: "39px",
                                                borderRadius: "9999px",
                                                backgroundColor: "#E8FAFF",
                                                color: "#0F4391",
                                                border: "1px solid #0F4391",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            CANCEL
                                        </Button>

                                        <Button
                                            variant="contained"
                                            disableElevation
                                            onClick={resetFilterData}
                                            style={{
                                                marginRight: "10px",
                                                top: "30px",
                                                borderColor: "#0F4391",
                                                width: "99px",
                                                height: "39px",
                                                borderRadius: "9999px",
                                                backgroundColor: "#E8FAFF",
                                                color: "#0F4391",
                                                border: "1px solid #0F4391",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            RESET
                                        </Button>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                                getListData(paginationdata, "");
                                            }}
                                            // onClick={handleFilterData}
                                            style={{
                                                top: "30px",
                                                borderColor: "#0F4391",
                                                width: "99px",
                                                height: "39px",
                                                borderRadius: "9999px",
                                                backgroundColor: "#E8FAFF",
                                                color: "#0F4391",
                                                border: "1px solid #0F4391",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            SEARCH
                                        </Button>
                                    </Box>
                                </Modal>
                            </div>
                        </Grid>

                        {items && items.length > 0 ? (
                            <>
                                <div className="container  scrollit ">
                                    <table
                                        style={{
                                            marginLeft: "20px",
                                            width: "90%",
                                        }}
                                        className="table-auto w-full border-collapse"
                                    >
                                        <thead
                                            style={{ position: "sticky", top: "0", zIndex: "1" }}
                                        >
                                            <tr className="tablebor">
                                                <th className="m-r-f">ePRO Plan Name</th>
                                                <th className="m-r-f">Description</th>
                                                <th className="m-r-f">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item) => (
                                                <tr key={item.eproPlanMasterId} className="border-t ">
                                                    <td
                                                        className="m-r-f"
                                                        onClick={() =>
                                                            navigate(
                                                                `/dashboard/epro/edit/${item.eproPlanMasterId}`
                                                            )
                                                        }
                                                    >
                                                        {item.eproPlanMasterName}
                                                    </td>
                                                    <td className="m-r-f">{item.description}</td>
                                                    <td className=" m-r-f">
                                                        <span
                                                            className={
                                                                item.status === "ACTIVE" ? "dotg" : "dotr"
                                                            }
                                                        ></span>{" "}
                                                        {item.status === "ACTIVE" ? "ACTIVE" : " INACTIVE"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="record">No Records Found</div>
                            </>
                        )}
                    </Grid>
                </Grid>
                <AdminCookieConsentBanner />
            </div>
        </div>
    );
};

export default Epro;