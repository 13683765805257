import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokensFromStorage } from "../globalFunctions/getLocalStorageData";

export const adminLogInRequest = createApi({
  reducerPath: "adminLogInRequest",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    // baseUrl:"https://edge.sit.karkinos.in/"
  }),

  endpoints: (builder) => ({
    // admin login Request Start
    adminLogIn: builder.mutation({
      query: (adminAuthDetails) => {
        return {
          url: "auth/user/login",
          method: "POST",
          body: adminAuthDetails,
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
        };
      },
    }),
    // admin login Request end

    // adminLogout request start

    adminLogout: builder.mutation({
      query: (adminId) => {
        const tok = getTokensFromStorage();

        return {
          url: "auth/logout",
          method: "POST",
          body: adminId,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    // adminLogout request end

    // adminChangePasswordRequest Start

    adminChangePassword: builder.mutation({
      query: (newPasswordWithAdminId) => {
        const tok = getTokensFromStorage();

        return {
          url: "auth/user/resetPassword",
          method: "PUT",
          body: newPasswordWithAdminId,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    // adminChangePasswordRequest End

    // adminForgotPasswordRequestStart

    adminForgotPassword: builder.mutation({
      query: (userName) => {
        return {
          url: `auth/user/forgotPassword/${userName}`,
          method: "PUT",

          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
      },
    }),

    // adminForgotPasswordRequestEnd

    // gettingOrgDropDownValueWhichIsActive-End

    // creating organisation start

    creatingOrganization: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "mdm-admin/organization/",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatingOrganization: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "mdm-admin/organization/" + dataToSend.id,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    //new

    creatingLocation: builder.mutation({
      query: (createLocationData) => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/location/",
          method: "POST",
          body: createLocationData,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatingLocation: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "mdm-admin/location/" + dataToSend.id,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    // creating location end

    // gettingOrgDropDownValueWhichIsActive-Start

    getActiveOrgValue: builder.mutation({
      query: (page) => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/organization/active/true?page=" + page + "&size=500&sort=lastModifiedOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getAllOrgValue: builder.mutation({
      query: (page) => {
        const tok = getTokensFromStorage();
        return {
          url:
            "mdm-admin/organization/all?page=" +
            page +
            "&size=20&sort=createdOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    //  getting states list
    getActiveStateValue: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/geo/states",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getActiveDistrictValue: builder.mutation({
      query: (state) => {
        const tok = getTokensFromStorage();
        return {
          // url:'mdm-admin/geo/districts/Maharashtra/false',
          url: "mdm-admin/geo/districts/" + state.name + "/false",
          method: "GET",
          // body:adminAuthDetails,
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    // get active location value start

    getActiveLocValue: builder.mutation({
      query: (page) => {
        const tok = getTokensFromStorage();
        return {
          url:
            "mdm-admin/location/all?page=" +
            page +
            "&size=20&sort=createdOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getActiveLocationValue: builder.mutation({
      query: (data) => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/location/status/ACTIVE?page=" + data + "&size=500&sort=lastModifiedOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    // nee
    getRoleList: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "auth/roles",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    // get active user value start
    getActiveUserValue: builder.mutation({
      query: (page) => {
        const tok = getTokensFromStorage();
        return {
          // url:"auth/user/list?deletedFlag=false&sort=createdOn,desc",
          url:
            "auth/user/list?deletedFlag=false&page=" +
            page +
            "&size=20&sort=createdOn,desc",

          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getSearchUserList: builder.mutation({
      query: (data) => {

        const tok = getTokensFromStorage();
        return {
          url:
            "auth/user/search/" +
            data.string +
            "?page=" +
            data.page +
            "&size=20",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    creatingUser: builder.mutation({
      query: (createUserData) => {
        const tok = getTokensFromStorage();
        return {
          url: "/auth/user",
          method: "POST",
          body: createUserData,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatingUser: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "auth/user/" + dataToSend.userId,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    unlockUser: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "auth/user/unlock/" + dataToSend,
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    // sample create api

    creatingSample: builder.mutation({
      query: (createSampleData) => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/sample-master/type",
          method: "POST",
          body: createSampleData,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getsampleclssList: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/sample-master/classification",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getsamplematchingList: builder.mutation({
      query: (data) => {
        const tok = getTokensFromStorage();
        return {
          url:
            "/mdm-admin/sample-master/type/matching?searchStr=" +
            data.string +
            "&page=" +
            data.page +
            "&size=200&sort=createdOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatigsample: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "mdm-admin/sample-master/type",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    searchOrganization: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url:
            "mdm-admin/organization/by-name/" +
            dataToSend +
            "/?isExactMatch=false",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    searchlocation: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url:
            "mdm-admin/location/by-name/" + dataToSend + "/?isExactMatch=false",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    searchUser: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/auth/user/search/" + dataToSend + "/?isExactMatch=false",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    searchsample: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "mdm-admin/sample-master/type/matching?searchStr=" + dataToSend,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatingservicerequest: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        if (dataToSend.statusChange === true) {
          return {
            url:
              dataToSend.status === "ACTIVE"
                ? "/mdm-admin/service-request-master/?status=SUSPENDED"
                : "/mdm-admin/service-request-master/?status=ACTIVE",
            method: "PUT",
            body: dataToSend,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + tok[0],
            },
          };
        } else {
          return {
            url:
              "/mdm-admin/service-request-master/?status=" + dataToSend.status,
            method: "PUT",
            body: dataToSend,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + tok[0],
            },
          };
        }
      },
    }),
    createservicerequest: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/service-request-master/",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getservicereqlist: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url:
            "/mdm-admin/service-request-master/all?status=&searchStr=" +
            dataToSend.string +
            "&page=" +
            dataToSend.page +
            "&size=20&sort=createdOn,desc",
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getservicereqSearchlist: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url:
            "/mdm-admin/service-request-master/all?status=&searchStr=" +
            dataToSend.string +
            "&page=" +
            dataToSend.page +
            "&size=20&sort=createdOn,desc",
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    putServicePackage: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        let url = "";
        var status = "";
        if (dataToSend.statusVal === false) {
          status = dataToSend.status === "ACTIVE" ? "ACTIVE" : "CANCELLED";
        } else {
          status = dataToSend.status === "ACTIVE" ? "CANCELLED" : "ACTIVE";
        }
        if (dataToSend.isUpdate) {
          url = `/mdm-admin/servicePackage/?override=true&status=${status}`;
        } else {
          url = `/mdm-admin/servicePackage/?override=false`;
        }

        return {
          url,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatePackageStatus: builder.mutation({
      query: (dataToSend, status) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/servicePackage/?override=true&status=${dataToSend.status}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getPackageDropdown: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/service-request-master/all?status=ACTIVE&searchStr=&page=0&size=20&sort=createdOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getPackageList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: `/mdm-admin/servicePackage/all?status=&searchStr=${dataToSend.searchStr
            }&page=${Number(dataToSend.page)}&size=20&sort=createdOn,desc`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getTemplateUrlList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "bulkUpload/template",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createValidateAndImport: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "patientImport/import",
          method: "POST",
          body: dataToSend,
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getpatientstatuslist: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "https://run.mocky.io/v3/90de0bfe-61d1-4c74-b94a-6e1914d508f0",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getpatientpagination: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `patientImport/requests/all?page=${dataToSend}&size=20`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createFetchData: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "patientImport/jobId",
          method: "POST",
          body: dataToSend,
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createPatientImport: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        const formData = new FormData();
        formData.append("file", dataToSend);
        formData.append("fileName", dataToSend.name);

        return {
          url: "patientImport/import",
          method: "POST",
          body: formData,
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getencountertypeslist: builder.mutation({
      query: (encounterName) => {
        const tok = getTokensFromStorage();
        return {
          url: "encounters/encounterTypeList?encounterName=" + encounterName,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getEncounterTypeData: builder.mutation({
      query: (encounterTypeId) => {
        const tok = getTokensFromStorage();
        return {
          url: `/encounters/state-info/${encounterTypeId}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getSystemStates: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "/encounters/active-system-states",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteSystemState: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/user-definded-states/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updateSystemStates: builder.mutation({
      query: (dataTosend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/user-definded-states/",
          body: dataTosend,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    updateUserRole: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "auth/user/roles/remove/" + dataToSend.userId,
          body: dataToSend.roles,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getSearchUseremailList: builder.mutation({
      query: (data) => {
        const tok = getTokensFromStorage();
        return {
          url: "auth/user/by-email/" + data,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getEncounterTypeName: builder.mutation({
      query: (lastModifiedBy) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/encountertype/by-name/v2?encounterName=${lastModifiedBy}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getBodySitesList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/body-site-code/all?page=${dataToSend}&size=20&sort=createdOn,desc`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    putBodySites: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/body-site-code/`,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    searchBodySites: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/body-site-code/search-by-filter/${dataToSend}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    softDeleteUser: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "auth/user/" + dataToSend.userId,
          // body: dataToSend.roles,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    canInactivateRes: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "user/can-inactivate",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getTerminology: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "global/getbyconstant/terminology_list",
          // body: dataToSend,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    filterSampleData: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/sample-master/type/fetch-sample-type-master?page=${dataToSend.page}&size=20`,
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    filterLocationData: builder.mutation({
      query: (data) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/location/filter?sort=createdOn,desc&name=${data.locName}&partOf=${data.partOf}&managingOrganization=${data.managingOrg}&state=${data.state}&city=${data.city}&status=${data.status}&page=${data.page}&size=${data.size}`,
          // body: dataToSend,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    filterLocationLevelData: builder.mutation({
      query: (data) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/location/filter?sort=createdOn,desc&name=${data.locName}&level=${data.loclevel}&partOf=${data.partOf}&managingOrganization=${data.managingOrg}&state=${data.state}&city=${data.city}&status=${data.status}&page=${data.page}&size=${data.size}`,
          // body: dataToSend,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    filterPackageData: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/servicePackage/fetch-service-package?sort=createdOn,desc&page=${dataToSend.page}&size=20`,
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    filterOrganizationData: builder.mutation({
      query: (dataToSend) => {
        let payload = {
          ...dataToSend,
          ["status"]:
            dataToSend.status === "Active"
              ? true
              : dataToSend.status === "Inactive"
                ? false
                : "",
        };
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/organization/fetch-organizations?sort=createdOn,desc&page=${dataToSend.page}&size=${dataToSend.size}`,
          body: payload,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    filterServiceReqData: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/service-request-master/fetch-service-request-masters?sort=createdOn,desc&page=${dataToSend.page}&size=20`,
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    filterUserData: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/auth/fetch-users?sort=createdOn,desc&page=${dataToSend.page}&size=20&deletedFlag=false`,
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getHealthCareListdata: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `mdm-admin/practitioner/all?searchStr=${dataToSend.string}&active=&sort=createdOn,desc&page=${dataToSend.page}&size=20`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getRoleByUserId: builder.mutation({
      query: (userId) => {
        const tok = getTokensFromStorage();
        return {
          url: "auth/user/roles/" + userId,
          // body: dataToSend.roles,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getResetToken: builder.mutation({
      query: (resettoken) => {
        const tok = getTokensFromStorage();
        return {
          url: "auth/user/reset-token/?refresh-token=" + resettoken,
          // body: dataToSend.roles,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatePractitionerHealthcare: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/practitioner/" + dataToSend.id,
          body: dataToSend,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getSpacializationList: builder.mutation({
      query: (string) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/specialization/?searchStr=" + string + "&active=" + true + "&page=0&size=500&sort=lastModifiedOn,desc",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),



    getPhotoById: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/practitioner/photo/" + id,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getSignatureById: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/practitioner/signature/" + id,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createHealthcarePractitioner: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/practitioner/",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    practitionerHealthcareSave: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/practitioner/all?userId=" + dataToSend,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    healthcarePractitionerDelete: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/practitioner/" + dataToSend,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    mobileValidation: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "/global/getbyconstant/mobile_validation",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getTaskTypeList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/task-management/task/task-type/?searchStr=${dataToSend.string}&status=&page=${dataToSend.page}&size=20&sort=createdOn,desc`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createTaskType: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/task-management/task/task-type/",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getTaskResolutionList: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();
        return {
          url: `/task-management/task/task-resolution/?searchStr=${dataToSend.searchStr}&status=${dataToSend.status}&sort=createdOn,desc&page=${dataToSend.page}&size=20`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getEproList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        let payload = {
          ...dataToSend,
          ["status"]:
            dataToSend.status === "ACTIVE"
              ? true
              : dataToSend.status === "INACTIVE"
                ? false
                : "",
        };
        return {
          url: `/mdm-admin/epro-plan-master/search/all?eproPlanMasterName=${payload.string}&isActive=${payload.status}&page=${payload.page}&size=${payload.size}&sort=lastModifiedOn,desc`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getEproQuestionnaire: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/epro-questionnaire-master/fetch-by-parameters",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getEproByUserId: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        console.log(dataToSend.id);
        return {
          url: `/mdm-admin/epro-plan-master/${dataToSend.id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    updateEprobyUserId: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/epro-plan-master/",
          body: dataToSend,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createTaskResolution: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/task-management/task/task-resolution/",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getUsageCountResolution: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/task-management/task/task-resolution/${dataToSend}?size=100`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createTagMaster: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/tag/master/",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    updateTagMaster: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/tag/master/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),


    getTagList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/tag/master/by-tag-master-names?sort=createdOn,desc&page=0&size=20",
          body: [],
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getTagByTagMasterName: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/tag/master/matching/${dataToSend.tagMasterName}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getUserByUserId: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/auth/users",
          body: [dataToSend],
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getUsageCountTag: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/patient/tag/${dataToSend}?size=1`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getTaskTypeUsageCountTag: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/task-management/task/search`,
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getByConstant: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/global/getbyconstant/" + dataToSend,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    // Medicinestart//
    getMedicineduration: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-duration-uom-master/all?searchStr=" + dataToSend.string + "&active=&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicinedurationcreate: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-duration-uom-master/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicineroute: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-route/all?searchStr=" + dataToSend.string + "&active=true&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicineroutecreat: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-route/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicinemaster: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-master/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicinemastersearch: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-master/search/all?searchStr=" + dataToSend.searchString + "&strength=&isActive=true&sort=createdOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getMedicineStrengthUOM: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-strengthUOM/all?searchStr=" + dataToSend.string + "&active=&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicinedosage: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-dosage-instruction-master/all?searchStr=" + dataToSend.string + "&active=true&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicdosagemasterall: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-dosage-instruction-master/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getMedicineSiteList: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-site/all?searchStr=" + dataToSend.string + "&active=true&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),


    deleteMedicineMaster: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/medicine-master/delete-by-medicine-master-id/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteMedicineDosageMaster: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/medicine-dosage-instruction-master/delete-by-code/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteMedicineSite: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/medicine-site/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteMedicineRoute: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/medicine-route/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createupdateMedicineSite: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-site/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicdosageByCode: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-dosage-instruction-master/" + dataToSend.code,
          method: "GET",
          // body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicrouteByCode: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-route/" + dataToSend.code,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getMedicineSiteByCode: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-site/" + dataToSend.code,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createupdateMedicineStrengthUom: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-strengthUOM/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicineFrequencyByCode: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-frequency-master/" + dataToSend.code,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createMedicineFrequency: builder.mutation({
      query: (dataToSend) => {
        //  console.log(dataToSend);
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-frequency-master/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicineFrequencymaster: builder.mutation({
      query: (dataToSend) => {
        console.log(dataToSend);
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-frequency-master/all?searchStr=" + dataToSend.string + "&active=true&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getMedicineStrenthUomList: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/medicine-strengthUOM/all?searchStr=" + dataToSend.string + "&active=&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),


    getUserByMobileNumber: builder.mutation({
      query: (mobileno) => {

        const tok = getTokensFromStorage();

        return {
          url: "/auth/user/by-mobile/" + mobileno,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteMedicineStrenthUom: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/medicine-strengthUOM/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteMedicineDurationUom: builder.mutation({
      query: (id) => {
        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/medicine-duration-uom-master/delete-by-id/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getCreatingReportCategory: builder.mutation({
      query: (createReportCategoryData) => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/master-data/",
          method: "POST",
          body: createReportCategoryData,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getReportCategoryList: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/master-data/REPORT_CATEGORY",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createDoctorpanel: builder.mutation({
      query: (dataToSend) => {
        console.log(dataToSend);
        const tok = getTokensFromStorage();

        return {
          url: "/reporting/configuration/doctor-panel/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createHeaderFooter: builder.mutation({
      query: (dataToSend) => {
        //  console.log(dataToSend);
        const tok = getTokensFromStorage();

        return {
          url: "report/reporting/createupdate",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updatigHeaderFooter: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "report/reporting/createupdate",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getHeaderFooterList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/reporting/reporting-list?searchStr=" + dataToSend.string + "&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getLocationsById: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/location/location-ids",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    deleteheaderfooter: builder.mutation({
      query: (reporttypeId) => {
        const tok = getTokensFromStorage();
        return {
          url: `/reporting/configuration/report/${reporttypeId}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getmessagetemplateList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/template/?locale=&channel=&clientPatronName=",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createUpdateMessageTemplate: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/template/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getTemplateConfig: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "/global/getbyconstant/template_config",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createroledata: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/auth/role/${dataToSend.role}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getJobNameAndFailureCount: builder.mutation({
      query: () => {
        const tok = getTokensFromStorage();
        return {
          url: "/unified-search/metrics/data-sync/failure-job-count?eligibleForRetry=true",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    processFailedDataSyncJobs: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: `/data-sync/job-failure/trigger/${dataToSend.jobName}?operationType=${dataToSend.type}`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getJobdatalist: builder.mutation({
      query: (dataToSend) => {
        console.log(dataToSend);
        const tok = getTokensFromStorage();
        return {
          url: "/data-sync/job/names?active=true&searchStr=" + dataToSend.string + "&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createjobsdata: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();

        return {
          url: "data-sync/job-configuration-details/" + dataToSend.job_name,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    forgotPasswordV2: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/auth/user/forgot-password/v2",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          }
        }
      }
    }),

    programactivitycreate: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/program-activity/",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          }
        }
      }
    }),
    programactivityall: builder.mutation({
      query: (dataToSend) => {
        console.log(dataToSend)
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/program-activity/all?exactMatchProgramActivityName=false&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          }
        }
      }
    }),
    searchprogramactivity: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "mdm-admin/program-activity/all?exactMatchProgramActivityName=false",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    updatigtasktype: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/task-management/task/task-type/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    updatigtaskresolution: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/task-management/task/task-resolution",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getProgramList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/program/all?exactMatchProgramName=false&programName=" + dataToSend.string + "&sort=lastModifiedOn,desc&page=" + dataToSend.page + "&size=" + dataToSend.size,
          method: "POST",
          body: { "programName": dataToSend.string },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    searchprogramname: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/program/all?exactMatchProgramName=false&programName=screen&size=50",
          body: dataToSend,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createUpdateProgram: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/program/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    createProgramactivity: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/program-activity/",
          method: "POST",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    updateProgramactivity: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/mdm-admin/program-activity/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createUpdateCustomTask: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();

        return {
          url: "/task-management/custom-status/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getCustomTaskList: builder.mutation({
      query: (dataToSend) => {
        let payload = { "customTaskStatus": dataToSend.customTaskStatus }
        const tok = getTokensFromStorage();
        return {
          url: "/task-management/custom-status/?page=" + dataToSend.page + "&size=" + dataToSend.size + "&sort=lastModifiedOn,desc",
          method: "POST",
          body: payload,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createUpdateCustomTaskStatus: builder.mutation({
      query: (dataToSend) => {
        console.log(dataToSend[0].taskTypeId)
        const tok = getTokensFromStorage();
        return {
          url: "/task-management/task-type-custom-status/list/?taskTypeId=" + dataToSend[0].taskTypeId,
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getCustomTaskStatusByTaskTypeId: builder.mutation({
      query: (taskTypeId) => {
        const tok = getTokensFromStorage();
        return {
          url: "/unified-search/task/task-type-custom-status/" + taskTypeId + "?active=true",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    deleteTaskTypeCustomStatus: builder.mutation({
      query: (taskTypeCustomTaskStatusMapId) => {
        const tok = getTokensFromStorage();
        return {
          url: `/task-management/task-type-custom-status/${taskTypeCustomTaskStatusMapId}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getProcedureClassificationList: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/procedure/classification?classificationId=${dataToSend.searchStr}&sort=createdOn,desc&page=${dataToSend.page}&size=${dataToSend.size}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createUpdateProcedureClassification: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/procedure/classification/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getCategoryProcedureList: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/procedure/category?procedureCategoryId=${dataToSend.searchStr}&sort=createdOn,desc&page=${dataToSend.page}&size=${dataToSend.size}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createUpdateCategoryProcedure: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/procedure/category/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    getProcedureList: builder.mutation({
      query: (dataToSend) => {

        const tok = getTokensFromStorage();
        return {
          url: `/mdm-admin/procedure/master?procedureMasterId=${dataToSend.searchStr}&sort=createdOn,desc&page=${dataToSend.page}&size=${dataToSend.size}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),

    createUpdateProcedure: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/procedure/master/",
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    getCountryList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/country/all?page=0&size=500&searchStr=&sort=nationalityName,ASC",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    searchCountryList: builder.mutation({
      query: (dataToSend) => {
        const tok = getTokensFromStorage();
        return {
          url: "/mdm-admin/country/all?page=0&size=500&searchStr=" + dataToSend.searchStr + "&sort=nationalityName,ASC",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tok[0],
          },
        };
      },
    }),
    // end points end
  }),
});
export const {
  useAdminLogInMutation, useAdminLogoutMutation, useAdminChangePasswordMutation, useAdminForgotPasswordMutation,
  useGetActiveOrgValueMutation, useCreatingOrganizationMutation, useGetActiveLocValueMutation, useCreatingLocationMutation,
  useGetActiveStateValueMutation, useGetActiveDistrictValueMutation, useGetActiveUserValueMutation, useCreatingUserMutation,
  useUpdatingOrganizationMutation, useUpdatingUserMutation, useUpdatingLocationMutation, useGetRoleListMutation, useCreatingSampleMutation,
  useGetsampleclssListMutation, useGetsamplematchingListMutation, useUpdatigsampleMutation, useSearchOrganizationMutation,
  useSearchlocationMutation, useSearchUserMutation, useSearchsampleMutation, useGetSearchUserListMutation, useGetAllOrgValueMutation,
  useGetActiveLocationValueMutation, useCreateservicerequestMutation, useGetservicereqlistMutation, useUpdatingservicerequestMutation, useGetservicereqSearchlistMutation, usePutServicePackageMutation,
  useGetPackageDropdownMutation, useGetPackageListMutation, useUpdatePackageStatusMutation, useGetTemplateUrlListMutation,
  useCreateValidateAndImportMutation, useGetpatientstatuslistMutation, useGetpatientpaginationMutation, useCreatePatientImportMutation, useCreateFetchDataMutation,
  useGetencountertypeslistMutation, useGetEncounterTypeDataMutation, useGetSystemStatesMutation, useDeleteSystemStateMutation,
  useUpdateSystemStatesMutation, useUpdateUserRoleMutation, useGetSearchUseremailListMutation, useGetEncounterTypeNameMutation,
  useGetBodySitesListMutation, usePutBodySitesMutation, useSearchBodySitesMutation, useSoftDeleteUserMutation, useCanInactivateResMutation, useGetTerminologyMutation,
  useFilterSampleDataMutation, useFilterLocationDataMutation, useFilterPackageDataMutation, useFilterOrganizationDataMutation,
  useFilterServiceReqDataMutation, useFilterUserDataMutation, useGetHealthCareListdataMutation, useGetRoleByUserIdMutation,
  useUpdatePractitionerHealthcareMutation, useGetSpacializationListMutation, useGetPhotoByIdMutation, useGetSignatureByIdMutation,
  useCreateHealthcarePractitionerMutation, usePractitionerHealthcareSaveMutation, useHealthcarePractitionerDeleteMutation, useMobileValidationMutation,
  useGetResetTokenMutation, useGetTaskTypeListMutation, useCreateTaskTypeMutation, useGetTaskResolutionListMutation,
  useCreateTaskResolutionMutation, useCreateTagMasterMutation, useGetTagListMutation, useGetTagByTagMasterNameMutation, useGetUserByUserIdMutation,
  useGetUsageCountTagMutation, useGetTaskTypeUsageCountTagMutation, useGetUsageCountResolutionMutation, useUnlockUserMutation,
  useGetByConstantMutation, useGetMedicinedurationMutation, useGetMedicinedurationcreateMutation, useGetMedicinerouteMutation, useGetMedicineroutecreatMutation,
  useGetMedicinemasterMutation, useGetMedicinemastersearchMutation, useGetMedicineStrengthUOMMutation, useGetMedicinedosageMutation,
  useGetMedicdosagemasterallMutation, useGetMedicineSiteListMutation,
  useDeleteMedicineMasterMutation,
  useDeleteMedicineDosageMasterMutation,
  useDeleteMedicineSiteMutation,
  useGetEproListMutation,
  useGetEproByUserIdMutation,
  useUpdateEprobyUserIdMutation,
  useGetEproQuestionnaireMutation,
  useDeleteMedicineRouteMutation,
  useCreateupdateMedicineSiteMutation,
  useGetMedicdosageByCodeMutation,
  useGetMedicrouteByCodeMutation,
  useGetMedicineSiteByCodeMutation,
  useCreateupdateMedicineStrengthUomMutation,
  useDeleteMedicineStrenthUomMutation,
  useGetMedicineStrenthUomListMutation,
  useDeleteMedicineDurationUomMutation,
  useCreateMedicineFrequencyMutation,
  useGetMedicineFrequencymasterMutation,
  useGetMedicineFrequencyByCodeMutation,
  useGetCreatingReportCategoryMutation,
  useGetReportCategoryListMutation,
  useGetUserByMobileNumberMutation,
  useCreateDoctorpanelMutation,
  useCreateHeaderFooterMutation,
  useUpdatigHeaderFooterMutation,
  useGetHeaderFooterListMutation,
  useGetLocationsByIdMutation,
  useDeleteheaderfooterMutation,
  useGetmessagetemplateListMutation,
  useCreateUpdateMessageTemplateMutation,
  useGetTemplateConfigMutation,
  useGetCreateRolesListMutation,
  useCreateroledataMutation,
  useGetJobNameAndFailureCountMutation,
  useProcessFailedDataSyncJobsMutation,
  useGetJobdatalistMutation,
  useCreatejobsdataMutation,
  useForgotPasswordV2Mutation,
  useUpdatigtasktypeMutation,
  useUpdatigtaskresolutionMutation,
  useUpdateTagMasterMutation,
  useFilterLocationLevelDataMutation,
  useProgramactivitycreateMutation,
  useProgramactivityallMutation,
  useCreateUpdateProgramMutation,
  useSearchprogramactivityMutation,
  useGetProgramListMutation,
  useSearchprogramnameMutation,
  useCreateProgramactivityMutation,
  useCreateUpdateCustomTaskMutation,
  useGetCustomTaskListMutation,
  useCreateUpdateCustomTaskStatusMutation,
  useGetCustomTaskStatusByTaskTypeIdMutation,
  useDeleteTaskTypeCustomStatusMutation,
  useUpdateProgramactivityMutation,
  useGetProcedureClassificationListMutation, useCreateUpdateProcedureClassificationMutation,
  useGetCategoryProcedureListMutation, useCreateUpdateCategoryProcedureMutation,
  useCreateUpdateProcedureMutation, useGetProcedureListMutation,
  useGetCountryListMutation,
  useSearchCountryListMutation,

} = adminLogInRequest;
