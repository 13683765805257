import { useState, useEffect, useCallback } from "react";
import Header from "../global/header/Header";
import SideNav from "../global/sidenav/SideNav";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Divider from "@mui/material/Divider";
import sideImageDashboardHome from "../../images/sideImageDashboardHome.svg";
import MedicationIcon from "@mui/icons-material/Medication";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
    NavLink,
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
} from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { getAdminActualValue } from "../../globalFunctions/getLocalStorageData";
import {
    useAdminLogoutMutation,
    useGetResetTokenMutation,
} from "../../services/adminLogIn";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { checkIfAdminLoggedIn } from "../../features/adminLogged";
import {
    decryptAdminId,
    getTokensFromStorage,
} from "../../globalFunctions/getLocalStorageData";
import { color } from "@mui/system";
import circleimg from "../../images/Vector.jpg";

import "./user/Admin.css";
import ErrorBoundary from "../../logger/errorboundrycomp";

const AdminHome = (props) => {
    const [adminLogFun, adminLogRes] = useAdminLogoutMutation();
    const [getRefreshToken] = useGetResetTokenMutation();
    const location = useLocation();
    const adminSelector = true;
    const [adminDetails, setAdminDetails] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [drawerState, setDrawerState] = useState(false);
    const [linkAcitveDetails, setActiveLinkDetails] = useState({
        empty: true,
    });
    const [events, setEvents] = useState(["click", "load", "scroll"]);
    const [second, setSecond] = useState(0);

    const tok = getTokensFromStorage();
    const profileName = useSelector((state) => state.adminLog.profileName);

    // reset interval timer
    // let resetTimer = useCallback(() => {
    //   if(JSON.parse(atob(tok[0].split('.')[1])).exp*10000<Date.now()){
    //     navigate("/")
    //    }
    // }, );

    // Life cycle hook

    // useEffect(() => {
    //     events.forEach((event) => {
    //     window.addEventListener(event, resetTimer);
    //   });

    // }, [resetTimer]);
    useEffect(() => {
        if (
            tok &&
            tok[0] &&
            JSON.parse(atob(tok[0].split(".")[1])).exp * 1000 < Date.now()
        ) {
            if (sessionStorage.getItem("resetTokenFlag") == "false") {
                sessionStorage.setItem("resetTokenFlag", true);
                callRefreshTokenApi();
            }
        }
    }, [Date.now()]);

    const callRefreshTokenApi = async () => {
        const res = await getRefreshToken(tok[1]);
        if (res?.data?.accessToken) {
            sessionStorage.setItem("zExT1_a", res?.data?.accessToken);
            sessionStorage.setItem("e_Aophg", res?.data?.refreshToken);
            sessionStorage.setItem("resetTokenFlag", false);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        setAdminDetails(tok && tok[0] && JSON.parse(atob(tok[0].split(".")[1])));
    }, []);

    const [subListState, setSubListState] = useState({
        heading: "",
        icon: "",
        isSubListExpanded: false,
    });

    const sideNavState = {
        drawerState,
        setDrawerState,
    };

    const gettingState = (state) => {
        setDrawerState(state);
    };
    const detectingLinkFunction = (state) => {
        oneStepBack();
        setActiveLinkDetails((prev) => ({
            ...prev,
            empty: (linkAcitveDetails.empty = false),
            ...state,
        }));
    };

    const mainStyleForContent = {
        width: `calc(100% - 240px)`,
        marginLeft: "240px",
    };

    const bredStyle = {
        fontSize: "12px",
    };

    useEffect(() => {
        props.setexpand1({
            empty:
                linkAcitveDetails.shouldAlways &&
                (subListState.isSubListExpanded ||
                    linkAcitveDetails.category ||
                    linkAcitveDetails.patient ||
                    linkAcitveDetails.jobs),
        });
    }, [
        linkAcitveDetails.shouldAlways &&
        (subListState.isSubListExpanded ||
            linkAcitveDetails.category ||
            linkAcitveDetails.patient ||
            linkAcitveDetails.jobs),
    ]);

    const hideCategoryDrawer = () => {
        setActiveLinkDetails((prev) => ({
            ...prev,
            empty: (linkAcitveDetails.empty = false),
            category: (linkAcitveDetails.category = false),
            patient: (linkAcitveDetails.patient = false),
            jobs: (linkAcitveDetails.jobs = false),
        }));
    };

    const hideMenuBottomActionDrawer = () => {
        setActiveLinkDetails((prev) => ({
            ...prev,
            empty: (linkAcitveDetails.empty = false),
            menu: (linkAcitveDetails.menu = false),
        }));
    };

    const isLinkClicked = (activeLinkHeading, activeLinkIcon) => {
        if (activeLinkHeading) {
            setSubListState((prev) => ({
                ...prev,
                heading: (subListState.heading = activeLinkHeading),
                icon: (subListState.icon = activeLinkIcon),
                isSubListExpanded: (subListState.isSubListExpanded = true),
            }));
        }
    };
    const [access, setAccess] = useState(
        JSON.parse(sessionStorage.getItem("access"))
    );

    const oneStepBack = () => {
        setSubListState((prev) => ({
            ...prev,
            heading: (subListState.heading = ""),
            icon: (subListState.icon = ""),
            isSubListExpanded: (subListState.isSubListExpanded = false),
        }));
    };

    return (
        <>
            <ErrorBoundary>
                <Header getState={gettingState} />
                <SideNav
                    getStateWhichLinkIsActive={detectingLinkFunction}
                    passingStateToSideNav={sideNavState}
                />

                <main
                    className={
                        linkAcitveDetails.shouldAlways &&
                            (subListState.isSubListExpanded ||
                                linkAcitveDetails.category ||
                                linkAcitveDetails.patient ||
                                linkAcitveDetails.jobs)
                            ? " transition-all bg-d-color  duration-200 ease-linear w-full m-1 openmenu"
                            : "transition-all bg-d-color  duration-200 ease-linear w-full m-1"
                    }
                    style={
                        drawerState
                            ? mainStyleForContent
                            : { width: "calc(110% - 110px)", marginLeft: "110px" }
                    }
                >
                    {props.children}
                    {!subListState.isSubListExpanded ? (
                        (linkAcitveDetails.patient ||
                            linkAcitveDetails.category ||
                            linkAcitveDetails.jobs) &&
                            linkAcitveDetails.shouldAlways ? (
                            <section
                                className={
                                    "opensidebar bg-myColor  shadow-lg w-80 absolute flex justify-end items-end  bottom-0 z-50 h-screen Sidenav"
                                }
                                style={{ height: "calc(100vh - 48px)" }}
                            >
                                <div
                                    className=" mainContentContainer w-full overflow-scroll bg-white ml-3"
                                    style={{ height: "calc(100vh - 48px)" }}
                                >
                                    <div className="rightArrowContainer flex  justify-end items-center  h-10">
                                        <span
                                            onClick={hideCategoryDrawer}
                                            className=" flex justify-center items-center h-2/4 cursor-pointer rounded-l-md bg-iconColor"
                                        >
                                            {/* <img src={circleimg} alt="" className="spanimg" /> */}
                                            <KeyboardDoubleArrowLeftIcon
                                                style={{ fontSize: "25px", color: "#fff" }}
                                            />
                                        </span>
                                    </div>
                                    {linkAcitveDetails.category ? (
                                        <>
                                            {profileName === "KDS" ? (
                                                <ul>
                                                    <ListItemForCategoryDrawer
                                                        functionAsProp={isLinkClicked}
                                                        content="Create/Manage Locations, Organisations and more"
                                                        heading="Administration"
                                                        icon={
                                                            <MedicationIcon
                                                                style={{ fontSize: "40px", color: "#0F4391" }}
                                                            />
                                                        }
                                                    />
                                                    {(access?.ccadmin || access?.rolesview) && !(access?.ccadmin && !access?.rolesview) && (
                                                        <>
                                                            <ListItemForCategoryDrawer
                                                                functionAsProp={isLinkClicked}
                                                                content="Create/Manage Clinical Masters"
                                                                heading="Clinical"
                                                                icon={
                                                                    <NoteAddIcon
                                                                        style={{ fontSize: "40px", color: "#0F4391" }}
                                                                    />
                                                                }
                                                            />
                                                            <ListItemForCategoryDrawer
                                                                functionAsProp={isLinkClicked}
                                                                content="Create/Manage Procedure Masters"
                                                                heading="Procedure"
                                                                icon={
                                                                    <NoteAddIcon
                                                                        style={{ fontSize: "40px", color: "#0F4391" }}
                                                                    />
                                                                }
                                                            />
                                                            <ListItemForCategoryDrawer
                                                                functionAsProp={isLinkClicked}
                                                                content="Create/Manage Service Request"
                                                                heading="Services"
                                                                icon={
                                                                    <MiscellaneousServicesIcon
                                                                        style={{ fontSize: "40px", color: "#0F4391" }}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    <ListItemForCategoryDrawer
                                                        functionAsProp={isLinkClicked}
                                                        content="Create/Manage CC related masters"
                                                        heading="Command Centre"
                                                        icon={
                                                            <MedicationIcon
                                                                style={{ fontSize: "40px", color: "#0F4391" }}
                                                            />
                                                        }
                                                    />
                                                    {(access?.ccadmin || access?.rolesview) && !(access?.ccadmin && !access?.rolesview) && (
                                                        <ListItemForCategoryDrawer
                                                            functionAsProp={isLinkClicked}
                                                            content="Create/Manage Medicine Requests related masters"
                                                            heading="Medicine Request"
                                                            icon={
                                                                <MedicationIcon
                                                                    style={{ fontSize: "40px", color: "#0F4391" }}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                    {(access?.ccadmin || access?.rolesview) && !(access?.ccadmin && !access?.rolesview) && (

                                                        <ListItemForItem
                                                            functionAsProp={isLinkClicked}
                                                            content="Create/Manage ePRO Plans"
                                                            heading="ePRO Plans"
                                                            to="/dashboard/epro"
                                                            icon={
                                                                <MedicationIcon
                                                                    style={{ fontSize: "40px", color: "#0F4391" }}
                                                                />
                                                            }
                                                        />
                                                    )}

                                                </ul>
                                            ) : (
                                                <ul>
                                                    <ListItemForCategoryDrawer
                                                        functionAsProp={isLinkClicked}
                                                        content="Create/Manage Locations, Organisations and more"
                                                        heading="Administration"
                                                        icon={
                                                            <MedicationIcon
                                                                style={{ fontSize: "40px", color: "#0F4391" }}
                                                            />
                                                        }
                                                    />
                                                </ul>
                                            )}
                                        </>
                                    ) : null}
                                    {linkAcitveDetails.patient && (
                                        <ul>
                                            <SubList
                                                goBackSubList={hideCategoryDrawer}
                                                icon={""}
                                                heading={"Bulk Import"}
                                                linkAcitveDetails={linkAcitveDetails}
                                            />
                                        </ul>
                                    )}
                                    {linkAcitveDetails.jobs && (
                                        <ul>
                                            <SubList
                                                goBackSubList={hideCategoryDrawer}
                                                icon={""}
                                                heading={"Jobs"}
                                                linkAcitveDetails={linkAcitveDetails}
                                            />
                                        </ul>
                                    )}
                                </div>
                            </section>
                        ) : (
                            ""
                        )
                    ) : linkAcitveDetails.category ||
                        linkAcitveDetails.patient ||
                        linkAcitveDetails.jobs ? (
                        <SubList
                            goBackSubList={oneStepBack}
                            goBackSubList1={hideCategoryDrawer}
                            icon={subListState.icon}
                            heading={subListState.heading}
                            linkAcitveDetails={linkAcitveDetails}
                        />
                    ) : (
                        setSubListState((prev) => ({
                            ...prev,
                            heading: (subListState.heading = ""),
                            icon: (subListState.icon = ""),
                            isSubListExpanded: (subListState.isSubListExpanded = false),
                        }))
                    )}

                    {/* {
            linkAcitveDetails.menu && linkAcitveDetails.shouldAlways ?
              <section className="bg-myColor  shadow-lg w-80 absolute flex justify-end items-end  bottom-0 z-50 h-screen " style={{ height: '100vh' }}>
                <div className=" mainContentContainer w-full bg-white ml-3" style=
                  {{ height: '100vh' }}>
                  <div className="rightArrowContainer flex bg-iconColor justify-end items-center  h-8">
                    <span onClick={() => hideMenuBottomActionDrawer()} className=" flex justify-center items-center h-2/4 cursor-pointer rounded-l-md bg-white">
                      <KeyboardDoubleArrowLeftIcon style={{ fontSize: '25px', color: '#0F4391' }} />
                    </span>
                  </div>

                  <div className="userProfileDetails pb-5 flex justify-around items-start  bg-iconColor h-16 w-full">
                    <div className="profileIconContainer ml-2 flex justify-center items-center bg-white  h-11 w-11 rounded-full">
                      <strong className="text-2xl">{adminDetails.name.split("")[0]}</strong>
                    </div>
                    <div className="profileIconContainer  h-full w-2/3">
                      <h2 className="font-medium text-white text-base">{adminDetails && adminDetails.name && adminDetails.name}</h2>
                      <p className="font-normal text-white text-sm">{adminDetails && adminDetails.roles && adminDetails.roles[0]}</p>
                    </div>

                  </div>


                  <div className="linkContainer flex flex-col justify-end  overflow-y-auto bg-white" style={{ height: 'calc(100% - 96px)' }}>
                    <ul className="list-none">
                      <NavLink to="/">
                        <li className="flex pl-8 mb-5 cursor-pointer"> <LogoutIcon style={{ color: '#0F4391' }} /> <span className="text-lg leading-snug font-medium pl-5  text-iconColor">Logout</span></li>
                      </NavLink>

                      <NavLink to="/dashboard/change-password" style={(isActive) => { return { color: `${isActive ? 'red' : ''}` } }}>
                        <li className="flex pl-8 mb-5 cursor-pointer"> <LockOpenIcon style={{ color: '#0F4391' }} /> <span className="text-lg leading-snug font-medium pl-5  text-iconColor">Change Passowrd</span> </li>
                      </NavLink>
                      <hr />
                      <em className="pl-8 text-iconColor text-xs">Privacy Policy. Terms of Use</em>
                    </ul>
                  </div>
                </div>
              </section>
              : ""

          } */}
                </main>
            </ErrorBoundary>
        </>
    );
};

const SubList = (props) => {
    const [access, setAccess] = useState(
        JSON.parse(sessionStorage.getItem("access"))
    );
    const profileName = useSelector((state) => state.adminLog.profileName);
    console.log(access?.rolesview);

    return (
        <>
            <section
                className="opensidebar bg-myColor shrink-0 shadow-lg w-80 absolute flex justify-end items-end  bottom-0 z-50 h-screen Sidenav"
                style={{ height: "calc(100vh - 48px)" }}
            >
                <div
                    className=" mainContentContainer w-full bg-white ml-3"
                    style={{ height: "calc(100vh - 48px)" }}
                >
                    <div className="rightArrowContainer flex  justify-end items-center h-10">
                        <span
                            onClick={() => props.goBackSubList()}
                            className=" flex justify-center items-center h-2/4 cursor-pointer rounded-l-md bg-iconColor"
                        >
                            <KeyboardDoubleArrowLeftIcon
                                style={{ fontSize: "25px", color: "#fff" }}
                            />
                            {/* <img src={circleimg} alt="" className="spanimg" /> */}
                        </span>
                    </div>
                    <div className="activeLinkSubDrawerHeading flex items-center  h-10">
                        <div className="w-4/5 flex justify-start items-center h-full ml-4">
                            <h1 className="text-iconColor admin_side_menu">
                                {props.heading}
                            </h1>
                        </div>
                    </div>
                    {props.heading === "Administration" &&
                        props.linkAcitveDetails.category && (
                            <>
                                {profileName === "KDS" ? (
                                    <ul onClick={() => props.goBackSubList1()}>
                                        <div className="scrollit2">
                                            {access?.organisationview && (
                                                <SubListItem
                                                    to="/dashboard/organization"
                                                    content="Create/Manage Locations , Organisations"
                                                    heading="Organisation"
                                                />
                                            )}
                                            {access?.locationview && (
                                                <SubListItem
                                                    to="/dashboard/location"
                                                    content="Create/Manage Locations"
                                                    heading="Location"
                                                />
                                            )}
                                            {access?.locationview && (
                                                <SubListItem
                                                    to="/dashboard/Program"
                                                    content="Create/Manage Program"
                                                    heading="Program"
                                                />
                                            )}
                                            {access?.locationview && (
                                                <SubListItem
                                                    to="/dashboard/ProgramActivity"
                                                    content="Create/Manage Program activities"
                                                    heading="Program Activity"
                                                />
                                            )}
                                            {/* <SubListItem to="/roles" content="Create/Manage Roles & Permissions" heading="Roles & Permission" /> */}
                                            {access?.userview && (
                                                <SubListItem
                                                    to="/dashboard/user"
                                                    content="Create/Manage Users & Assign Roles"
                                                    heading="Users"
                                                />
                                            )}
                                            {access?.healthcarepractitionerview && (
                                                <SubListItem
                                                    to="/dashboard/healthcarePractitioner"
                                                    class="healthcare-nav-item"
                                                    content="Create/Manage Healthcare Practitioner"
                                                    heading="Healthcare Practitioner"
                                                />
                                            )}
                                            {access?.rolesview && (
                                                <SubListItem
                                                    to="/dashboard/CreateRoles"
                                                    class="healthcare-nav-item"
                                                    content="Create/Manage Create Roles"
                                                    heading="Create Roles"
                                                />
                                            )}
                                        </div>
                                    </ul>
                                ) : (
                                    <ul onClick={() => props.goBackSubList1()}>
                                        {access?.userview && (
                                            <SubListItem
                                                to="/dashboard/user"
                                                content="Create/Manage Users & Assign Roles"
                                                heading="Users"
                                            />
                                        )}

                                        <SubListItem
                                            to="/dashboard/CreateRoles"
                                            class="healthcare-nav-item"
                                            content="Create/Manage Create Roles"
                                            heading="Create Roles"
                                        />
                                    </ul>
                                )}
                            </>
                        )}
                    {props.heading === "Clinical" && props.linkAcitveDetails.category && (
                        <ul onClick={() => props.goBackSubList1()}>
                            {access?.encounteview && (
                                <SubListItem
                                    to="/dashboard/encounterTypes"
                                    content="Create/Manage Encounter States "
                                    heading="Encounter States"
                                />
                            )}
                            {access?.bodysiteview && (
                                <SubListItem
                                    to="/dashboard/bodysites"
                                    content="Create/Manage Bodysites"
                                    heading="Bodysites"
                                />
                            )}
                            <SubListItem
                                to="/dashboard/reportCategory"
                                content="Define report Category/configuration"
                                heading="Report Category"
                            />
                            <SubListItem
                                to="/dashboard/Doctorpanel"
                                content="Location and Encounter specific doctor's panel"
                                heading="Doctor's Panel "
                            />
                            <SubListItem
                                to="/dashboard/headerFooter"
                                content="Create/Manage headerFooter"
                                heading="Header And Footer"
                            />
                        </ul>
                    )}

                    {props.heading === "Procedure" && props.linkAcitveDetails.category && (
                        <ul onClick={() => props.goBackSubList1()}>
                            {access?.encounteview && (
                                <SubListItem
                                    to="/dashboard/classificationprocedure"
                                    content="Create/Manage procedure classification "
                                    heading="Procedure Classification"
                                />
                            )}
                            {access?.bodysiteview && (
                                <SubListItem
                                    to="/dashboard/categoryprocedure"
                                    content="Create/Manage procedure category"
                                    heading="Procedure Category"
                                />
                            )}
                            <SubListItem
                                to="/dashboard/procedure"
                                content="Create/Manage procedure"
                                heading="Procedure"
                            />

                        </ul>
                    )}


                    {props.heading === "Services" && props.linkAcitveDetails.category && (
                        <ul onClick={() => props.goBackSubList1()}>
                            {access?.sampleview && (
                                <SubListItem
                                    to="/dashboard/sample"
                                    content="Create/Manage Sample , Sample"
                                    heading="Sample"
                                />
                            )}
                            {access?.servicerequestview && (
                                <SubListItem
                                    to="/dashboard/servicereq"
                                    content="Create/Manage Service Request"
                                    heading="Service Request"
                                />
                            )}
                            {access?.packageview && (
                                <SubListItem
                                    to="/dashboard/package"
                                    content="Create/Manage Packages"
                                    heading="Package"
                                />
                            )}
                            {/* <SubListItem to="/roles" content="Create/Manage Roles & Permissions" heading="Roles & Permission" /> */}
                            {/* <SubListItem to="/dashboard/user" content="Create/Manage Users & Assign Roles" heading="Users" /> */}
                        </ul>
                    )}
                    {props.heading === "Command Centre" &&
                        props.linkAcitveDetails.category && (
                            <ul onClick={() => props.goBackSubList1()}>
                                {access?.tasktypeview && (
                                    <SubListItem
                                        to="/dashboard/taskType"
                                        content="Create/Manage Task Type"
                                        heading="Task Type"
                                    />
                                )}
                                {access?.taskresolutionview && (
                                    <SubListItem
                                        to="/dashboard/taskResolution"
                                        content="Create/Manage Task Resolution"
                                        heading="Task Resolution"
                                    />
                                )}
                                {access?.tagsview && (
                                    <SubListItem
                                        to="/dashboard/tags"
                                        content="Create/Manage Tags"
                                        heading="Tags"
                                    />
                                )}
                                {access?.tagsview && (
                                    <SubListItem
                                        to="/dashboard/messagetemplate"
                                        content="Create/Manage templates"
                                        heading="Message Template"
                                    />
                                )}

                                <SubListItem
                                    to="/dashboard/taskstatus"
                                    content="Create/Task Status"
                                    heading="Task Status"
                                />
                            </ul>

                        )}
                    {props.heading === "Medicine Request" &&
                        props.linkAcitveDetails.category && (
                            <ul onClick={() => props.goBackSubList1()}>
                                <div className="scrollit2">
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/medicinemaster"
                                            content="Create/Manage Medicine Master"
                                            heading="Medicine Master"
                                        />
                                    )}
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/medicinedosage"
                                            class="healthcare-nav-item"
                                            content="Create/Manage Medicine Dosage Instruction"
                                            heading="Medicine Dosage Instruction"
                                        />
                                    )}
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/medicineroute"
                                            content="Create/Manage Medicine Route"
                                            heading="Medicine Route"
                                        />
                                    )}
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/medicinesite"
                                            content="Create/Manage Medicine Site"
                                            heading="Medicine Site"
                                        />
                                    )}
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/MedicineFrequency"
                                            content="Create/Manage Medicine Frequency"
                                            heading="Medicine Frequency"
                                        />
                                    )}
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/medicineduration"
                                            class="healthcare-nav-item"
                                            content="Create/Manage Medicine Duration UOM"
                                            heading="Medicine Duration UOM"
                                        />
                                    )}
                                    {access?.sampleview && (
                                        <SubListItem
                                            to="/dashboard/medicinestrenthuom"
                                            class="healthcare-nav-item"
                                            content="Create/Manage Medicine Strength UOM"
                                            heading="Medicine Strength UOM"
                                        />
                                    )}
                                </div>
                            </ul>
                        )}
                    {props.heading === "Bulk Import" &&
                        props.linkAcitveDetails.patient && (
                            <ul onClick={() => props.goBackSubList()}>
                                {access?.bulkimportadd && (
                                    <SubListItem
                                        to="/dashboard/patientimport"
                                        content="Create New Records"
                                        heading="Bulk Import"
                                    />
                                )}
                                {access?.bulkimportview && (
                                    <SubListItem
                                        to="/dashboard/patientstatuslist"
                                        content="View Import Logs"
                                        heading="Import logs"
                                    />
                                )}
                            </ul>
                        )}

                    {props.heading === "Jobs" && props.linkAcitveDetails.jobs && (
                        <ul onClick={() => props.goBackSubList()}>
                            <SubListItem
                                to="/dashboard/Jobs"
                                class="healthcare-nav-item"
                                content="Create/Manage Data Sync Jobs"
                                heading="Data Sync Jobs"
                            />
                            <SubListItem
                                to="/dashboard/FailedDataSyncJobs"
                                class="healthcare-nav-item"
                                content="Create/Manage Failed Sync Jobs"
                                heading="Failed Sync Jobs"
                            />
                        </ul>
                    )}
                </div>
            </section>
        </>
    );
};

const SubListItem = (props) => {
    return (
        <>
            <NavLink to={props.to}>
                <li className="p-2 hover:bg-myColor cursor-pointer w-full h-20 flex mt-3">
                    <div className="categoryDrawerContentContainer flex flex-col justify-around w-10/12 h-full">
                        <div className="categoryDrawerListItemHeading">
                            <h1
                                className={
                                    props.class +
                                    " text-iconColor text-lg pl-2 font-mono organ m-s-f"
                                }
                            >
                                {props.heading}
                            </h1>
                        </div>

                        <div className="categoryDrawerListItemHeading mt-1">
                            <p className="text-iconColor leading-tight  pl-2 setcolor text-lg m-r-f heading m-r-f">
                                {props.content}
                            </p>
                        </div>
                    </div>
                </li>
            </NavLink>
        </>
    );
};

const ListItemForCategoryDrawer = (props) => {
    return (
        <>
            <li
                onClick={() => props.functionAsProp(props.heading, props.icon)}
                className="hover:bg-myColor cursor-pointer w-full h-20 flex mt-3"
            >
                <div className="categoryDrawerContentContainer flex flex-col  w-10/12 h-full org-pad ">
                    <div className="categoryDrawerListItemHeading ">
                        <h1 className="text-iconColor admin_side_menu  ml-2 m-r-f">
                            {props.heading}
                        </h1>
                    </div>

                    <div className="categoryDrawerListItemHeading">
                        <p className="text-iconColor admin_side_menu_desc ml-2 m-r-f">
                            {props.content}` `
                        </p>
                    </div>
                </div>
            </li>
        </>
    );
};

const ListItemForItem = (props) => {
    return (
        <>
            <NavLink to={props.to}>
                <li
                    className="hover:bg-myColor cursor-pointer w-full h-20 flex mt-3"
                >
                    <div className="categoryDrawerContentContainer flex flex-col  w-10/12 h-full org-pad ">
                        <div className="categoryDrawerListItemHeading ">
                            <h1 className="text-iconColor admin_side_menu  ml-2 m-r-f">
                                {props.heading}
                            </h1>
                        </div>

                        <div className="categoryDrawerListItemHeading">
                            <p className="text-iconColor admin_side_menu_desc ml-2 m-r-f">
                                {props.content}` `
                            </p>
                        </div>
                    </div>
                </li>
            </NavLink>
        </>
    );
};


export default AdminHome;
