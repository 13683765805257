import { BrowserRouter as MainRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Login from "../layout/auth/Login";
import { getTokensFromStorage } from "../globalFunctions/getLocalStorageData";

import AdminHome from "../layout/dashboard/AdminHome";
import Home from "../layout/dashboard/home/Home";
import Post from "../layout/post/Post";
import Layout from "../layout/Layout";
import Organ from "../layout/dashboard/orga/Organ";
import { ConstructionOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ChangePassword from "../layout/dashboard/changePassword/ChangePassword";
import Logout from "../layout/auth/Logout";
import ForgotPassword from "../layout/auth/forgotpassword/ForgotPassword";
import Location from "../layout/dashboard/location/Location";
import Program from "../layout/dashboard/program/programName"
import User from "../layout/dashboard/user/user";
import Organization from "../layout/dashboard/Organization/organization";
import HealthcarePractitioner from "../layout/dashboard/healthcarePractitioner/healthcarePractitioner";
import Sample from "../layout/dashboard/sample/sample";
import SampleReq from "../layout/dashboard/samplerequest/samplereq";
import Package from "../layout/dashboard/package/package";
import BodySites from "../layout/dashboard/bodysites/bodysites";
import ErrorBoundary from "../logger/errorboundrycomp";
import PatientImport from "../layout/dashboard/patient/patientimport";
import PatientStatusList from "../layout/dashboard/patient/patientstatuslist";
import EncounterTypes from "../layout/dashboard/encounterState/encountertypes";
import TaskType from "../layout/dashboard/taskType/taskType";
import TaskResolution from "../layout/dashboard/taskResolution/taskResolution";
import Tags from "../layout/dashboard/tags/tags";
import MedicineMaster from "../layout/dashboard/medicineMaster/medicineMaster";
import MedicineDosage from "../layout/dashboard/MedicineDosageMaster/MedicineDosage"
import Medicineroute from "../layout/dashboard/Medicineroute/Medicineroute";
import MedicineDuration from "../layout/dashboard/MedicineDuration/MedicineDuration";
import MedicineSite from "../layout/dashboard/medicineSiteMaster/medicineSite";
import StrenthUom from "../layout/dashboard/medicineStrenthUom/strenthUom";
import MedicineFrequency from "../layout/dashboard/MedicineFrequencymaster/MedicineFrequency";
import ReportCategory from "../layout/dashboard/reportCategory/ReportCategory"
import Doctorpanel from "../layout/dashboard/Doctorspanel/Doctorpanel";
import HeaderFooter from "../layout/dashboard/headerFooter/headerFooter";
import MessageTemplate from "../layout/dashboard/messagetemplate/messageTemplate";
import CreateRoles from "../layout/dashboard/createRoles/createRoles"
import FailedDataSyncJobs from "../layout/dashboard/failedDataSyncJobs/failedDataSyncJobs"
import Jobs from "../layout/dashboard/Jobs/jobs"
import AdminCookieConsentBanner from "../layout/dashboard/home/AdminCookieConsentBanner";
import TaskStatus from "../layout/dashboard/taskStatus/taskStatus";
import ProgramActivity from "../layout/dashboard/programActivity/programActivity"
import ClassProcedure from "../layout/dashboard/classificationProcedure/classProcedure";
import CategoryProcedure from "../layout/dashboard/categoryProcedure/categoryProcedure";
import Procedure from "../layout/dashboard/procedureMaster/procedure";
import Epro from "../layout/dashboard/ePro/Epro";
import EProPlanForm from "../layout/dashboard/ePro/eproPlanForm.jsx";


const ProtectedRoute = ({ children }) => {
    const tok = getTokensFromStorage();

    const isAdminLogSuccess = useSelector((state) => state.adminLog.isAdminLogged);
    if (!isAdminLogSuccess && tok && tok[0] === null) {
        return <Navigate to="/" replace />;
    }
    else {
        return (children)
    }
};

const App = () => {

    const [expand, setexpand] = useState({
        heading: "",
        icon: "",
        isSubListExpanded: false
    })
    const [expand1, setexpand1] = useState({
        empty: true
    });

    return (
        <>
            <MainRouter>
                <Routes>



                    {/* Private Route will be setup later   */}

                    <Route path="/" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/" element={<Logout />} />

                    <>
                        <Route path="/dashboard" element={<ProtectedRoute> <AdminHome setexpand1={setexpand1} setexpand={setexpand} ><Home expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="/dashboard/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
                        <Route path="dashboard/location" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Location expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/Program" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Program expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/ProgramActivity" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <ProgramActivity expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/user" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand} ><User expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/organization" element={<ProtectedRoute> <AdminHome setexpand1={setexpand1} setexpand={setexpand} ><Organization expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/healthcarePractitioner" element={<ProtectedRoute> <AdminHome setexpand1={setexpand1} setexpand={setexpand} ><HealthcarePractitioner expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/sample" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Sample expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/servicereq" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <SampleReq expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/package" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Package expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/patientimport" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand} ><PatientImport expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/patientstatuslist" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <PatientStatusList expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/encountertypes" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <EncounterTypes expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/bodysites" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <BodySites expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/taskType" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <TaskType expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/taskResolution" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <TaskResolution expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/tags" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Tags expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicinemaster" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MedicineMaster expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicineDosage" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MedicineDosage expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicineroute" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Medicineroute expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicineduration" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MedicineDuration expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicinedosage" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MedicineDosage expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicineroute" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Medicineroute expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicinesite" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MedicineSite expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/medicinestrenthuom" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <StrenthUom expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/MedicineFrequency" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MedicineFrequency expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/reportCategory" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <ReportCategory expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/Doctorpanel" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Doctorpanel expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/headerFooter" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <HeaderFooter expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/messagetemplate" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <MessageTemplate expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/CreateRoles" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <CreateRoles expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/FailedDataSyncJobs" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <FailedDataSyncJobs expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/Jobs" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Jobs expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/AdminCookieConsentBanner" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <AdminCookieConsentBanner expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/taskstatus" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <TaskStatus expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/classificationprocedure" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <ClassProcedure expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/categoryprocedure" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <CategoryProcedure expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/procedure" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Procedure expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route path="dashboard/epro" element={<ProtectedRoute><AdminHome setexpand1={setexpand1} setexpand={setexpand}> <Epro expand1={expand1} /></AdminHome></ProtectedRoute>} />
                        <Route
                            path="dashboard/epro/new"
                            element={
                                <ProtectedRoute>
                                    <AdminHome setexpand1={setexpand1} setexpand={setexpand}>
                                        <EProPlanForm expand1={expand1} />
                                    </AdminHome>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="dashboard/epro/edit/:planId"
                            element={
                                <ProtectedRoute>
                                    <AdminHome setexpand1={setexpand1} setexpand={setexpand}>
                                        <EProPlanForm expand1={expand1} />
                                    </AdminHome>
                                </ProtectedRoute>
                            }
                        />
                    </>
                </Routes>
            </MainRouter>
        </>
    )
}

export default App;