import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import useUserHook from "./userLogic";
import UserTable from "./usertable";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import moment from "moment";

import {
  useGetActiveOrgValueMutation,
  useGetActiveUserValueMutation,
  useGetSearchUserListMutation,
  useGetSearchUseremailListMutation,
  useGetRoleListMutation,
  useGetActiveLocationValueMutation,
  useSearchUserMutation,
  useGetpatientpaginationMutation,
  useSoftDeleteUserMutation,
  useCanInactivateResMutation,
  useFilterUserDataMutation,
  useUnlockUserMutation,
  useGetUserByUserIdMutation,
  useFilterOrganizationDataMutation,
  useFilterLocationDataMutation,
} from "../../../services/adminLogIn";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
import { list } from "postcss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));

const User = (props) => {
  const classes = useStyles();
  const statuslist = ["Active", "Inactive"];
  const [isHealthcarePractitioner, setIsHealthcarePractitioner] =
    useState(false);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const navigate = useNavigate();
  const deleteModalDesc =
    "This activity will unlock the users and the users can login to the products and perform all actions based on the existing access.";
  const cancelModalDesc = "User Unlocked Successfully";
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [userList, setUserList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [Photo, setPhoto] = useState("");

  const [userpageList, setuserpageList] = useState([]);
  const [userImport, setuserImport] = useState();
  // const [array, setarray] = useState(rolelist)

  const getuserpageList = async (page) => {
    // if (serachVal.length < 2) {
    //   getActiveUserFun(page - 1);
    // } else {
    //   let data = {};
    //   data.page = page - 1;
    //   data.string = serachVal;
    //   callListApiFunct(data)
    // }
    setCurrentPage(page);
    setIsNewData(false);
    let data = { ...filterData, ["page"]: page - 1, ["string"]: serachVal };
    callListApiFunct(data);
  };
  const handleunlockerow = (item) => {
    setCurrentRow(item);
    setModalOpen(true);
    setunlockModal(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCancelModal(false);
    setunlockModal(false);
  };
  const handleProceed = async () => {
    hideForm();
    const res = await UnlockUser(currentRow?.userId);
    if (res?.data?.userId) {
      toasterFunction(
        "User Unlocked Successfully", "success"
      );

    } else {
      toasterFunction(
        "Invalid Operations, User's account is not locked", "error"
      );
    }

    setModalOpen(false);
    // setModalOpen(true);
    // setCancelModal(true);
    // setunlockModal(false);
  };

  const [getActiveLocFun] = useGetActiveLocationValueMutation();
  const [getFilterList] = useFilterUserDataMutation();
  const [getLocationList] = useFilterLocationDataMutation();
  const [UnlockUser] = useUnlockUserMutation();
  const [getActiveValFun, { isLoading, isSuccess, isError, data }] =
    useGetActiveOrgValueMutation();
  const [getActiveUserFun, { ...user }] = useGetActiveUserValueMutation();
  const [getsearchuserListFun, { ...searchList }] =
    useGetSearchUserListMutation();
  const [getsearchuseremailListFun, { ...searchemailList }] =
    useGetSearchUseremailListMutation();
  const [currentRow, setCurrentRow] = useState();
  const [currentemail, setcurrentemail] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [unlockModal, setunlockModal] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [isProfilePhotoAction, setIsProfilePhotoAction] = useState(false);
  const [getpatientpaginationfunc] = useGetpatientpaginationMutation();
  const [isUserCanInactivate] = useCanInactivateResMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [getOrganizationList] = useFilterOrganizationDataMutation();
  const [fullName, setFullName] = useState("");
  const [getrolelistfunc, { isLoading2, isSuccess2, isError2, ...rolelist }] =
    useGetRoleListMutation();

  const {
    orgSubmitHandler,
    whenInputsChange,
    openUserFilterModal,
    orgState,
    dropOrgState,
    userAllTextFieldState,
    isUserError,
    showerror,
    handleUserFilterModalOpen,
    handleUserFilterModalClose,
    setErrorMessage,
    setRoleList,
    handleDropDownChange,
    createUserRes,
    updateUserRes,
    districtlist,
    filterData,
    taskSubmitStatus,
    resetUserFilterData,
    handleUserFilterInputChange,
    handleUserFilterValueChange,
    resetErrorHandler,
    setUserTextFieldState,
    setDropOrgState,
    setDropLocState,
    halndleClickonProceed,
    handleOpen,
    setOpen,
    open,

    handleClose,
    setCurrentPage,
    currentPage,
  } = useUserHook();

  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  function EmailEditField({ initialEmail }) {
    const [email, setEmail] = useState(initialEmail);

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    }
  }
  const [isopenemail, setisopenemail] = useState(true)
  const [canInactivate, setcanInactivate] = useState(true);
  const [genderlist, setGenderList] = useState([
    "MALE",
    "FEMALE",
    "TRANSGENDER",
    "OTHER",
    "UNKNOWN",
  ]);
  const [salutationlist, setSalutationList] = useState([
    "Miss.",
    "Mrs.",
    "Mr.",
    "Dr.",
  ]);
  const [serachVal, setsearchVal] = useState("");
  const filterStatusOptions = ["Active", "Inactive"];
  const [id, setId] = useState("");
  const [isNewData, setIsNewData] = useState(true);
  const [isLoadingValue, setisLoadingValue] = useState(false);
  const [data1, setdata1] = useState({});
  const [activeLocations, setActiveLocation] = useState({});
  const [activeOrganisation, setActiveOrganisation] = useState({});
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });

  const showFormActions = () => {
    if (access?.userview == true) {
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
      }
    } else {
      toasterFunction(" No Permission");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const onChange = async (e, field) => {
    const file = e.target.files[0];
    if (file.size > 512000) {
      setSizeError(true);
      setIsProfilePhotoAction(false);
    } else {
      setSizeError(false);
      const base64 = await convertToBase64(file);
      setPhoto(base64.split(",")[1]);
      setUserTextFieldState((prev) => ({
        ...prev,
        [field]: base64.split(",")[1],
      }));
      setIsProfilePhotoAction(true);
    }
  };
  const hideForm = () => {
    setSizeError(false);
    setsearchVal("");
    setId("");
    setUserTextFieldState({});

    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
      setUserTextFieldState({
        is_update: false,
        isopenemail: true,
        userId: "",
        userName: "",
        salutation: "",
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        email: "",
        mobileNumber: "",
        roles: [],
        organizations: [],
        locations: [],
        profilePhoto: "",
      });
      setPhoto("");
    }
  };

  const isUserCanInactivateFunc = async (datatosend) => {
    let res = await isUserCanInactivate(datatosend);
    setcanInactivate(res?.data?.canInactivate);
  };
  const bredStyle = {
    fontSize: "12px",
  };

  const getFilterFormattedData = (data) => {
    var activeStatus = "";
    if (filterData.status == "Active")
      activeStatus = "false";
    else if (filterData.status == "Inactive")
      activeStatus = "true";
    return {
      fullName: data
        ? data.string
        : filterData.lastName != ""
          ? filterData.firstName + " " + filterData.lastName
          : filterData.firstName,
      email: filterData.emailId,
      roles: filterData.role,
      organizationIds: filterData.organizationName.map((item) => item.id),
      locationIds: filterData.locationName.map((item) => item.id),
      status: activeStatus,
      page: data ? data.page : 0,
    };
  };

  const callListApiFunct = async (data) => {
    if (data?.page === 0 || data === undefined) {
      setIsNewData(true);
    }
    // new Cancer Screening CKBP
    const reqBody = getFilterFormattedData(data ? data : null);
    const res = await getFilterList(reqBody);
    var response = [];
    res?.data?.content.forEach((element, index) => {
      let result = element.roles.find((item) => item == "lms");
      if (!(result == "lms")) {
        response.push(element);
      }
    });
    setUserList(response);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
  };
  const callActiveListApiFunct = async (data) => {
    const res = await getActiveValFun();
    if (res?.data?.content) {
      setdata1(res);
    }
  };
  const handleOrgDropDownSearch = (e, value) => {
    callListOrgDropdownData(value);
  };
  const callListOrgDropdownData = async (value) => {
    const payload = {
      organizationName: value,
      partOf: "",
      state: "",
      city: "",
      status: "Active",
      page: 0,
      size: 100,
    };
    const res = await getOrganizationList(payload);
    if (res?.data?.content) setActiveOrganisation(res);
  };
  const callListLocDropdownData = async (value) => {
    const payload = {
      locName: value,
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "ACTIVE",
      page: 0,
      size: 100,
    };
    const res = await getLocationList(payload);
    if (res?.data?.content) setActiveLocation({ ...res });

  };

  const handleLocDropDownSearch = (e, value) => {

    callListLocDropdownData(value);
  };

  const handleFilterClose = async () => {
    let data = {
      ...filterData,
      ["fullName"]:
        filterData.lastName != ""
          ? filterData.firstName + " " + filterData.lastName
          : filterData.firstName,
    };
    const res = await getFilterList(data);
    setUserList(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
    setsearchVal("");
    handleUserFilterModalClose();
    //resetUserFilterData();
  };

  const callFilterListApiFunct = async () => {
    setsearchVal(
      filterData.lastName != ""
        ? filterData.firstName + " " + filterData.lastName
        : filterData.firstName
    );
    if (filterData.page === 0) {
      setIsNewData(true);
    }

    const reqBody = {
      fullName:
        filterData.lastName != ""
          ? filterData.firstName + " " + filterData.lastName
          : filterData.firstName,
      email: filterData.emailId,
      roles: filterData.role,
      organizationIds: filterData.organizationName.map((item) => item.id),
      locationIds: filterData.locationName.map((item) => item.id),
      status:
        filterData.status === "Inactive"
          ? "true"
          : filterData.status === "Active"
            ? "false"
            : "",
      page: 0,
    };

    const res = await getFilterList(reqBody);
    setUserList(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
    handleUserFilterModalClose();
  };

  useEffect(() => {
    setisLoadingValue(true);
    getActiveValFun(paginationdata.page);
    getActiveLocFun("");
    callListLocDropdownData("");
    callListOrgDropdownData("");
    callActiveListApiFunct();
    getActiveUserFun(0);
    getrolelistfunc();
    callListApiFunct();
    setisLoadingValue(false);
  }, []);

  useEffect(() => {
    if (createUserRes.isSuccess || updateUserRes.isSuccess) {
      setUserTextFieldState({
        is_update: false,
        isopenemail: true,
        userId: "",
        userName: "",
        salutation: "",
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        dob: "",
        mobileNumber: "",
        roles: [],
        organizations: {},
        locations: {},
        profilePhoto: "",
        imageType: "",
        imageUrl: "",
      });
      hideForm();
      setDropOrgState({});
      setDropLocState({});
      callListOrgDropdownData("");
      callListLocDropdownData("");
      getActiveUserFun(0);
      callActiveListApiFunct();
      getActiveValFun(paginationdata.page);
      getActiveLocFun("");
      setIsNewData(true);
      callListApiFunct();
    }
  }, [createUserRes.isSuccess || updateUserRes.isSuccess]);

  useEffect(() => {
    if (updateUserRes.isSuccess) {
      setUserTextFieldState({
        is_update: false,
        isopenemail: true,
        deleted: false,
        userId: "",
        userName: "",
        salutation: "",
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        dob: "",
        mobileNumber: "",
        roles: [],
        organizations: [],
        locations: [],
        profilePhoto: "",
        imageType: "",
        imageUrl: "",
      });
      hideForm();
      getActiveValFun(paginationdata.page);
      callListLocDropdownData("");
      callListOrgDropdownData("");
      getActiveLocFun("");
      getActiveUserFun(0);
      callActiveListApiFunct();
      setPhoto({});
      callListApiFunct();
    }
  }, [updateUserRes.isSuccess]);


  // useEffect(() => {
  //   if (user && user.data && user.data.content.length > 0) {
  //     setUserList([...user.data.content]);
  //     setTotalPage(user?.data?.totalPages);
  //     setTotalPageCalculate({
  //       numberOfElements: user?.data?.numberOfElements,
  //       number: user?.data?.number,
  //       totalElements: user?.data?.totalElements,
  //     });
  //   }
  // }, [user && user.isSuccess]);

  // useEffect(() => {
  //   if (
  //     serachVal.length > 2 &&
  //     searchList &&
  //     searchList?.data &&
  //     searchList?.data?.content
  //   ) {
  //     setUserList([...searchList.data.content]);
  //     setTotalPage(searchList?.data?.totalPages);
  //     setTotalPageCalculate({
  //       numberOfElements: searchList.data?.numberOfElements,
  //       number: searchList.data?.number,
  //       totalElements: searchList.data?.totalElements,
  //     });
  //   } else {
  //     if (serachVal.length > 2 && searchList && searchList.isLoading == true) {
  //       setUserList([]);
  //     }
  //   }
  // }, [searchList && searchList.isSuccess]);

  // useEffect(() => {
  //   if (serachVal.length > 2 && searchemailList && searchemailList?.data) {
  //     setUserList([searchemailList.data]);

  //     setTotalPage(0);
  //   } else {
  //     if (
  //       serachVal.length > 2 &&
  //       searchemailList &&
  //       searchemailList.isLoading == true
  //     ) {
  //       setUserList([]);
  //     }
  //   }
  // }, [searchemailList && searchemailList.isSuccess]);
  const searchuserevent = async (value) => {
    setisLoadingValue(true);
    setsearchVal(value);
    if (value.length > 2) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(value)) {
        let data = {
          fullName: "",
          email: value,
          roles: [],
          organizationIds: [],
          locationIds: [],
          status: "",
          page: 0,
        };
        const res = await getFilterList(data);
        if (res?.data?.content) {
          setUserList(res?.data?.content);
          setTotalPage(res?.data?.totalPages);
          setTotalPageCalculate({
            numberOfElements: res?.data?.numberOfElements,
            number: res?.data?.number,
            totalElements: res?.data?.totalElements,
          });
        } else {
          setUserList([]);
        }

        // getFilterList(value);
      } else {
        if (/\d/.test(value) && value.length > 7) {
          let data = {
            fullName: "",
            email: "",
            mobileNumber: value,
            roles: [],
            organizationIds: [],
            locationIds: [],
            status: "",
            page: 0,
          };
          const res = await getFilterList(data);
          if (res?.data?.content) {
            setUserList(res?.data?.content);
            setTotalPage(res?.data?.totalPages);
            setTotalPageCalculate({
              numberOfElements: res?.data?.numberOfElements,
              number: res?.data?.number,
              totalElements: res?.data?.totalElements,
            });
          } else {
            setUserList([]);
          }
        } else {
          if (!/\d/.test(value)) {
            let data = {};
            data.page = 0;
            data.string = value;
            callListApiFunct({ ...data, ...filterData });
          }
        }

        // invalid email, maybe show an error to the user.
      }
    }
    if (value.length === 0) {
      callListApiFunct();
      setIsNewData(true);
    }
    setisLoadingValue(false);
  };

  const handleUserFormSubmit = (e) => {
    if (isHealthcarePractitioner) {
      navigate("/dashboard/healthcarePractitioner", {
        state: { ...userAllTextFieldState, isPractitioner: true },
        oldpath: "/dashboard/user",

      });
    } else {
      orgSubmitHandler(e);

    }
  };

  //   useEffect(() => {
  //     if(taskSubmitStatus) {
  //       toast("Task type Created successfully", {
  //         position: "top-center",
  //         autoClose: 2000,
  // hideProgressBar: true,
  //         type: "success",
  //         closeOnClick: true,
  //         theme: "light",
  //       });
  //     }
  //   }, [taskSubmitStatus])



  const getUserByIdFunct = async (userId) => {
    const res = await getUserByUserId(userId);


    //const res = await getUserByUserId(userId);
    if (res?.data?.content.length > 0) {
      setFullName(res?.data?.content[0].fullName);
    }
    else {
      setFullName("system")
    }
  };

  const filterActiveLocations = (content, selectedLocation) => {
    return content.filter(
      (locationItem) => locationItem.id !== selectedLocation.id
    );
  };

  const getLocationsContent = (content, values) => {
    // let locationValues = values;
    // if(locationValues?.length) {
    //   locationValues.forEach((selectedLocation) => {
    //     const activeLocations = filterActiveLocations(content, selectedLocation);
    //     locationValues = locationValues.filter((item) => item.id !== selectedLocation.id);
    //     if(locationValues.length === 0) {
    //       return activeLocations;
    //     }
    //     else {
    //       getLocationsContent(activeLocations, locationValues);
    //     }
    //   });
    // }
    // else return content;
    let finalData = content;
    values.forEach((selectedLocation) => {
      const data = finalData.filter(
        (activeLocation) => activeLocation.id !== selectedLocation.id
      );
      finalData = data;
    });
    return finalData;
  };

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Administration"
          thirdtab="User"
        ></Breadcrums>

        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className="">
                <Grid className=" p-7 ">
                  <TextField
                    label=""
                    onChange={(e) => searchuserevent(e.target.value)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    sx={{ m: 1, width: "25ch" }}
                    value={serachVal}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid className="editContainer rounded-radiusFourPix ">
                  <Grid
                    onClick={showFormActions}
                    className={
                      isFormVisible.isOpen ? "addIconchange" : "addIcon"
                    }
                    data-testid="open"
                  >
                    <span className=" p-2 m-r-f ">Add</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                        color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"
                          }`,
                      }}
                    />
                  </Grid>

                  {/* <Grid className="Addaction">
                      <span className="p-2 m-r-f">Actions</span>
                      <AddCircleOutlineIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} />
                    </Grid> */}

                  <Grid className="addIcon" onClick={handleUserFilterModalOpen}>
                    <span className="p-2 m-r-f">Filter</span>
                    <img
                      src={filter && filter}
                      alt="filter.png"
                      style={{
                        position: "absolute",
                        left: "90%",
                        right: "20%",
                        top: "29%",
                        bottom: "92%",
                        width: "11px",
                        paddingTop: "3px",
                      }}
                    ></img>
                  </Grid>
                  <Modal
                    open={openUserFilterModal}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      style={{
                        width: "450px",
                        padding: "50px",
                        borderColor: "white",
                      }}
                    >
                      <Typography
                        className="filtermain"
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                          fontFamily: "MontserratRegular",
                          marginTop: "5px",
                        }}
                      >
                        Filter by
                      </Typography>

                      <div className="OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          First Name
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => handleUserFilterInputChange(e)}
                          name="firstName"
                          value={filterData.firstName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Last Name
                        </h3>
                        <TextField
                          data-testid="lastName"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => handleUserFilterInputChange(e)}
                          name="lastName"
                          value={filterData.lastName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Email ID
                        </h3>
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          variant="standard"
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => handleUserFilterInputChange(e)}
                          name="emailId"
                          value={filterData.emailId}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                        />
                      </div>
                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Role
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={filterData.role}
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleUserFilterValueChange("role", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="loc-list-option"
                          options={
                            rolelist && rolelist.data ? rolelist.data : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Organization Name{" "}
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={filterData.organizationName}
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleUserFilterValueChange("organizationName", v)
                          }
                          onInputChange={(e, v, value) =>
                            handleOrgDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="org-list-option"
                          options={
                            activeOrganisation &&
                              activeOrganisation?.data &&
                              activeOrganisation?.data?.content &&
                              activeOrganisation?.data?.content.length > 0
                              ? activeOrganisation?.data?.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Location Name
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={filterData.locationName}
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleUserFilterValueChange("locationName", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handleLocDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            activeLocations &&
                              activeLocations.data &&
                              activeLocations.data.content &&
                              activeLocations.data.content.length > 0
                              ? activeLocations.data.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Status
                        </h3>
                        <Autocomplete
                          value={filterData.status}
                          autoHighlight={true}
                          disableClearable
                          onChange={(e, v) =>
                            handleUserFilterValueChange("status", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="org-list-option"
                          options={filterStatusOptions}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              onFocus={(e) => resetErrorHandler(e)}
                              autoComplete="OFF"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <Button
                        variant="contained"
                        disableElevation
                        onClick={handleFilterClose}
                        style={{
                          marginRight: "10px",
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "99px",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        CANCEL
                      </Button>

                      <Button
                        variant="contained"
                        disableElevation
                        onClick={resetUserFilterData}
                        style={{
                          marginRight: "10px",
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "99px",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={callFilterListApiFunct}
                        style={{
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "99px",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        Search
                      </Button>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>

              <>
                {isLoadingValue ? (
                  <div style={{ position: "fixed", left: "50%", top: "50%" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <UserTable
                    setRoleList={setRoleList}
                    setErrorMessage={setErrorMessage}
                    setId={setId}
                    id={id}
                    setPhoto={setPhoto}
                    setTotalPage={setTotalPage}
                    totalPage={totalPage}
                    getuserpageList={getuserpageList}
                    userList={userList}
                    setUserTextFieldState={setUserTextFieldState}
                    showFormActions={showFormActions}
                    isUserCanInactivateFunc={isUserCanInactivateFunc}
                    calculateData={calculateData}
                    currentPage={isNewData ? 1 : currentPage}
                    handleunlockerow={handleunlockerow}
                    userview={access?.userview}
                    userupdate={access?.userupdate}
                    unlockuser={access?.unlockuser}
                    getUserByIdFunct={getUserByIdFunct}
                    setcurrentemail={setcurrentemail}
                  />
                )}
                <Modal
                  open={modalOpen}
                  onClose={handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{ width: "400px" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <Alert severity="info">{deleteModalDesc}</Alert>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div className="mt-10 flex justify-center items-center  towButtonContainer">
                        <Button
                          type="submit"
                          onClick={handleModalClose}
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          {"CANCEL"}
                        </Button>

                        <Button
                          type="submit"
                          onClick={handleProceed}
                          variant="contained"
                          disableElevation
                          style={{
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          {"PROCEED"}
                        </Button>
                      </div>
                    </Typography>
                  </Box>
                </Modal>
              </>
            </div>
          </Grid>
          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {userAllTextFieldState.deleted === true
                            ? "Are you sure you want to reactivate  the Sample ?"
                            : "Are you sure you want to Deactivate the user ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {/* {userAllTextFieldState.status === true
                          ? "This activity will enable the Sample and user will be able to access it across all products."
                          : "This activity disable the Sample and user will not able to access it. You can reactivate the Sample at anytime?"} */}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="mainhead font-normal text-o-form-label-color flex justify-between items-center pl-8 pr-1 h-10">
                      {/* <div className="form-heading-org flex justify-between items-center pl-8 pr-1 h-10"> */}
                      <h1 id="useredit">User Overview</h1>
                      {/* <div className="closeIconContainer" onClick={hideForm}>
                            <IconButton color="warning" aria-label="upload picture" component="span">
                              <CloseIcon />
                            </IconButton>
                          </div> */}
                    </div>

                    <form autoComplete="off" onSubmit={handleUserFormSubmit}>
                      <div className=" pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Salutation <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.salutation
                          }
                          autoHighlight={true}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onChange={(e, v) =>
                            handleDropDownChange("salutation", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="loc-list-option"
                          options={salutationlist ? salutationlist : []}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid="salutation"
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              error={isUserError.isSalutationError === true}
                              label=" "
                              helperText={
                                isUserError.isSalutationError
                                  ? "Required !"
                                  : ""
                              }
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          First Name <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid="firstName"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isUserError.isfNameError === true}
                          helperText={
                            isUserError.isfNameError
                              ? "Valid (ID) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="firstName"
                          value={userAllTextFieldState.firstName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Middle Name{" "}
                        </h3>
                        <TextField
                          data-testid="middleName"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="middleName"
                          value={userAllTextFieldState.middleName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Last Name <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid="lastName"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isUserError.islNameError === true}
                          helperText={
                            isUserError.islNameError
                              ? "Valid (ID) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="lastName"
                          value={userAllTextFieldState.lastName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Date of Birth
                        </h3>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            maxDate={moment()}
                            value={userAllTextFieldState?.dob
                              ? moment(userAllTextFieldState?.dob) // Parse value with Moment.js
                              : null}
                            onChange={(newValue) => {

                              setUserTextFieldState((prev) => ({
                                ...prev,
                                dob: newValue ? newValue.toISOString() : null, // Convert Moment.js object to ISO string
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "black!important",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#1976d2!important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#1976d2!important",
                                    },
                                  },
                                }}
                              />
                            )}
                            variant="standard"
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Email ID <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid="email"
                          disabled={userAllTextFieldState.is_update && (userAllTextFieldState.email == userAllTextFieldState.userName)}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          variant="standard"
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isUserError.isEmailError === true}
                          helperText={
                            isUserError.isEmailError
                              ? "Valid (Email) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="email"
                          value={userAllTextFieldState.email}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3 className="font-normal text-o-form-label-color">
                          Gender <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.gender &&
                            userAllTextFieldState.gender
                          }
                          autoHighlight={true}
                          onChange={(e, v) => handleDropDownChange("gender", v)}
                          getOptionLabel={(option) => (option ? option : "")}
                          id="loc-list-option"
                          options={genderlist ? genderlist : []}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid="gender"
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              error={isUserError.isGenderError === true}
                              label=" "
                              helperText={
                                isUserError.isGenderError ? "Required !" : ""
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Mobile Number{" "}
                        </h3>
                        <TextField
                          data-testid="mobileNumber"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="mobileNumber"
                          value={userAllTextFieldState.mobileNumber}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Role <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.roles &&
                            userAllTextFieldState.roles
                          }
                          autoHighlight={true}
                          onChange={(e, v) => handleDropDownChange("role", v)}
                          getOptionLabel={(option) => (option ? option : "")}
                          getOptionDisabled={(option) =>
                            option === "practitioner" ? true : false
                          }
                          id="loc-list-option"
                          // options={
                          //   rolelist && rolelist.data ? rolelist.data : []
                          // }
                          options={
                            access?.rolesview === true
                              ? (rolelist && rolelist.data) || [] // All roles
                              : rolelist && rolelist.data
                                ? rolelist.data.filter((role) => role === "command_center")
                                : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid="roles"
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              error={isUserError.isDropLocError}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Organization Name{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.organizations &&
                            userAllTextFieldState.organizations
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("orgname", v)
                          }
                          onInputChange={(e, v, value) =>
                            handleOrgDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="org-list-option"
                          options={
                            activeOrganisation &&
                              activeOrganisation?.data &&
                              activeOrganisation?.data?.content &&
                              activeOrganisation?.data?.content?.length > 0
                              ? activeOrganisation?.data?.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid="organizations"
                              variant="standard"
                              sx={{ width: 358 }}
                              error={isUserError.isDropDownError}
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Location Name <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.locations &&
                            userAllTextFieldState.locations
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("locname", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handleLocDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            activeLocations &&
                              activeLocations.data &&
                              activeLocations.data.content &&
                              activeLocations.data.content.length > 0
                              ? getLocationsContent(
                                activeLocations.data.content,
                                userAllTextFieldState.locations
                              )
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid="locations"
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              error={isUserError.isDropLocError}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Photo
                        </h3>
                        <Button variant="contained" component="label">
                          {Photo ? "Update Photo" : "Upload Photo"}
                          <input
                            onFocus={(e) => resetErrorHandler(e)}
                            type="file"
                            hidden
                            name="profilePhoto"
                            id="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => onChange(e, "profilePhoto")}
                          />
                        </Button>
                        <br />
                        {Photo && (
                          <img
                            className="upload-image"
                            src={`data:image;base64,${Photo}`}
                            width="200px"
                            height={"100px"}
                          />
                        )}
                      </div>
                      {sizeError ? (
                        <div className="pl-5" style={{ color: "#d32f2f" }}>
                          Image size should be less than 500 KB
                        </div>
                      ) : null}

                      {userAllTextFieldState.is_update === true ? (
                        <>
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Created By
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled={userAllTextFieldState.is_update}
                                value={fullName}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                                variant="standard"
                              />
                            </p>
                            <h3 className="  mt-5 font-normal text-o-form-label-color">
                              Last Modified Date & Time
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled={userAllTextFieldState.is_update}
                                value={moment(
                                  userAllTextFieldState.lastModifiedTime * 1000
                                ).format("Do MMMM YYYY, h:mm:ss a")}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                                variant="standard"
                              />
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                userAllTextFieldState &&
                                  userAllTextFieldState.deleted == false
                                  ? "Active"
                                  : "Inactive"
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("status", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              disabled={!canInactivate}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="mt-5  OrgNameContainer ">
                        <div className="progressContainer mt-5 flex justify-center items-center">
                          {(createUserRes.isError || updateUserRes.isError) &&
                            showerror === true ? (
                            <Alert severity="error" color="warning">
                              {createUserRes.error &&
                                createUserRes.error.data &&
                                userAllTextFieldState.is_update === false
                                ? createUserRes.error.data.message
                                : updateUserRes.error &&
                                updateUserRes.error.data &&
                                updateUserRes.error.data.message}
                            </Alert>
                          ) : createUserRes.isLoading ||
                            updateUserRes.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className="mt-2 pl-5 OrgNameContainer">
                          <h3 className="font-normal text-o-form-label-color">Healthcare Practitioner</h3> */}
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Healthcare Practitioner
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setIsHealthcarePractitioner(e.target.checked)

                              }
                            />
                          </h3>
                          <h3 className=" mt-2 font-normal">
                            <span
                              className="m-s-f"
                              style={{ fontWeight: "500" }}
                            >
                              Note
                            </span>{" "}
                            : On Saving, The user would be redirected to Add
                            Page of Healthcare Practitioner.
                          </h3>
                        </div>

                        <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default User;
