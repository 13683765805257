import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ProgramNameLogic from "./programNameLogic";
import ProgramNameList from "./programNameList";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {
  useFilterOrganizationDataMutation,
  useFilterLocationDataMutation,
  useGetActiveStateValueMutation,
  useGetProgramListMutation,
  useGetUserByUserIdMutation,
  useSearchprogramnameMutation,
  useCreateUpdateProgramMutation,
  useGetLocationsByIdMutation,

} from "../../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ProgramName = (props) => {
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [getOrganizationList] = useFilterOrganizationDataMutation();
  const [getProgramList] = useGetProgramListMutation();
  const [searchprogramname] = useSearchprogramnameMutation();
  const [getStateValFun, { isLoading1, isSuccess1, isError1, ...data1 }] =
    useGetActiveStateValueMutation();
  const [getLocationList] = useFilterLocationDataMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [createUpdateProgram] = useCreateUpdateProgramMutation();
  const [getlocationdetailbyids] = useGetLocationsByIdMutation();

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    pageNumber: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const {
    whenInputsChange,
    AllTextFieldState,
    isError,
    dropdownState,
    setDropdowntate,
    handleDropDownChange,
    resetErrorHandler,
    setTextFieldState,
    setDropdownState,
    open,
    handleClose,
    setErrorMessage,
    showerror,
    setError,
    districtlist,
    getDistrictValFun1,
    isUserError,
    getDistrictValFun,
    halndleClickonProceed,
  } = ProgramNameLogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
  const [dropCity, setDropCity] = useState({});
  const [fullName, setFullName] = useState("");
  const [cityList, setcityList] = useState([]);
  const [activeOrganisation, setActiveOrganisation] = useState({});
  const [activeLocations, setActiveLocation] = useState({});
  const [programList, setProgramList] = useState({});
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState(0);
  const [MedicineFrequencyList, setMedicineFrequencyList] = useState([]);
  const [coordinatorError, setCoordinatorError] = useState("");
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
    size: 20,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const handleDelete = (item) => {
    setModalOpen(true);
    setCurrentRow(item);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const showFormActions = () => {
    if (access?.tagsview == true) {
      setErrorMessage(false);
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
        }
      }
    } else {
      toast(" No Permission", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };
  const hideForm = () => {
    setTextFieldState({
      is_update: false,
      firstName: "",
      startDate: "",
      endDate: "",
      organizations: [],
      locations: [],
      state: "",
      city: "",
      district: "",
      postalCode: "",
      latitude: "",
      longitude: "",
      salutation: "",
      status: "",
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setError((prev) => ({
      ...prev,
    }));
    setsearchVal("");
    setId("");
    programListData();
    setsearchVal("");

    const nonEmptyRows = formRows.filter((rowData) =>
      Object.values(rowData).some((value) => value !== "")
    );

    // Ensure that at least one row is always displayed
    if (nonEmptyRows.length === 0) {
      nonEmptyRows.push({
        salutation: "", // Set a default value for Salutation
        name: "",
        gender: "",
        mobile: "",
        email: "",
      });
    }

    setFormRows(nonEmptyRows);
    setFormRows([
      {
        salutation: "", // Set a default value for Salutation
        username: "",
        gender: "",
        mobile: "",
        email: "",
      },
    ]);
    setCoordinatorError("");
    setError({
      isNameError: false,
      isOrganizationError: false,
      isLocationError: false,
      // ...other error flags
    });
  };
  const onSearchevent = async (value) => {
    // if (value.length > 0) {
    // let data = { ...filterData, ["programName"]: value };
    setsearchVal(value);
    const res = await getProgramList({ string: value, page: 0, size: 20 });

    if (res?.data?.content) {
      setProgramList(res?.data?.content);
      setTotalPage(res?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
    }
    // }
    // if (value.length === 0) {
    //   programsearchdata();

    // }

  };

  // const [filterData, setFilterData] = useState({
  //   programName: "",

  // });
  // const programsearchdata= async (data) => {
  //   if (data?.page === 0 || data === undefined) {

  //   }
  //   const res = await searchprogramname(data ? data : filterData);;

  //   if (res?.data?.content) {
  //     setProgramList(res?.data?.content)
  //     setTotalPage(res?.data?.totalPages)
  //     setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })

  //   }
  // };
  const programListData = async (page) => {
    const res = await getProgramList({ string: "", page: page - 1, size: 20 });

    if (res?.data?.content) {
      setProgramList(res?.data?.content);
      setTotalPage(res?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
    }
  };
  const getUserByIdFunct = async (userId) => {
    const res = await getUserByUserId(userId);

    //const res = await getUserByUserId(userId);
    if (res?.data?.content?.length > 0) {
      setFullName(res?.data?.content[0].fullName);
    } else {
      setFullName("system");
    }
  };
  const handleRowClick = async (row) => {
    setId(row.programId);
    let locresult = []
    if (row?.locationIds?.length != 0) {
      locresult = await getlocationdetailbyids(row?.locationIds)
    }
    const data = {
      programName: row?.programName,
      startDate: row?.startDateTime,
      endDate: row?.endDateTime,
      description: row?.description,
      organizations: row?.organizationIds?.map(ele => ({ id: ele, name: ele })),
      locations: locresult && locresult?.data && locresult?.data?.length != 0 ? [...locresult?.data?.map(({ name, id }) => ({ name, id }))] : [],
      state: {
        name: row?.address?.state,
        isUt: false
      },
      district: { name: row?.address?.district },
      postalCode: row?.address?.postalCode,
      latitude: row?.address?.latitude,
      city: row?.address?.city,
      longitude: row?.address?.longitude,
      lastModifiedTime: row?.lastModifiedTime,
      is_update: true,
      programId: row?.programId,
      isDeleted: row?.isDeleted,
    };
    let coordinatorArray = []
    row?.coordinators?.forEach(element => {
      let data = {
        ...element,
        isNameError: false,
        isGenderError: false,
        isSalutationError: false,
        isEmailError: false,
        isMobileError: false,
      }
      coordinatorArray.push(data)
    });
    setFormRows([...coordinatorArray])
    getUserByIdFunct(row?.createdBy);

    setTextFieldState((prev) => ({ ...prev, ...data }));
  };
  const programListDataPage = async (page) => {
    const res = await getProgramList({ string: "", page: page - 1, size: 20 });
    setCurrentPage(page);
    if (res?.data?.content) {
      setProgramList(res?.data?.content);
      setTotalPage(res?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
    }
  };

  const [formRows, setFormRows] = useState([
    {
      salutation: "",
      isSalutationError: false,
      username: "",
      isNameError: false,
      gender: "",
      isGenderError: false,
      mobile: "",
      isMobileError: false,
      email: "",
      isEmailError: false,
    },
  ]);

  const [salutationlist, setSalutationList] = useState([
    "Miss.",
    "Mrs.",
    "Mr.",
    "Dr.",
  ]);
  const handleSalutationChange = (e, rowIndex, value) => {
    const updatedRows = [...formRows];
    updatedRows[rowIndex].salutation = value;
    updatedRows[rowIndex]["isSalutationError"] = false;
    setFormRows(updatedRows);
    if (value.trim() === "") {
      setCoordinatorError("Coordinator Name is required");
    }
  };

  const handleGenderChange = (e, rowIndex, value) => {
    const updatedRows = [...formRows];
    updatedRows[rowIndex].gender = value;
    updatedRows[rowIndex]["isGenderError"] = false;
    setFormRows(updatedRows);
    if (value.trim() === "") {
      setCoordinatorError("Gender Name is required");
    }
  };
  const [genderlist, setGenderList] = useState([
    "MALE",
    "FEMALE",
    "TRANSGENDER",
    "OTHER",
    "UNKNOWN",
  ]);

  // Function to handle input changes
  const handleInputChange = (e, rowIndex) => {
    const { name, value } = e.target;
    const updatedRows = [...formRows];
    updatedRows[rowIndex][name] = value;
    if (name === "username") {
      updatedRows[rowIndex]["isNameError"] = false;
    }
    if (name === "mobile") {
      updatedRows[rowIndex]["isMobileError"] = false;
    }
    if (name === "email") {
      updatedRows[rowIndex]["isEmailError"] = false;
    }
    setFormRows(updatedRows);
    if (name === "name" && value.trim() === "") {
      setCoordinatorError("Coordinator Name is required");
    }
  };


  const handleAddRow = () => {
    setFormRows([
      ...formRows,
      {
        salutation: "",
        username: "",
        gender: "",
        mobile: "",
        email: "",
      },
    ]);
  };

  const checkErrorInCoordinator = () => {
    let count = 0
    formRows.forEach(element => {
      if (element.username == "") {
        element.isNameError = true
        setCoordinatorError("Please fill in all required fields.");
        count = ++count
      }
      if (element.gender === "") {
        element.isGenderError = true
        setCoordinatorError("Please fill in all required fields.");
        count = ++count
      }
      if (element.mobile === "") {
        element.isMobileError = true
        setCoordinatorError("Please fill in all required fields.");
        count = ++count
      }
      if (element.email === "") {
        element.isEmailError = true
        setCoordinatorError("Please fill in all required fields.");
        count = ++count
      }
      if (element.salutation === "") {
        element.isSalutationError = true
        setCoordinatorError("Please fill in all required fields.");
        count = ++count
      }


    });
    if (count === 0) {
      setCoordinatorError("")
    }
    return count === 0 ? false : true
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();
    let hasValidationError = true;
    let callFunc = await checkErrorInCoordinator()
    // Your validation logic
    if (AllTextFieldState.programName === "") {
      setError((prev) => ({
        ...prev,
        isNameError: true,
        isOrganizationError: true,
        isLocationError: true,
      }));
      hasValidationError = true;
    } else if (!AllTextFieldState?.organizations?.length) {
      setError((prev) => ({
        ...prev,
        isNameError: false,
        isOrganizationError: true,
        isLocationError: true,
      }));
      hasValidationError = true;
    } else if (!AllTextFieldState?.locations?.length) {
      setError((prev) => ({
        ...prev,
        isNameError: false,
        isOrganizationError: false,
        isLocationError: true,
      }));
      hasValidationError = true;
    } else if (coordinatorError === "") {
      setError((prev) => ({
        ...prev,
        isNameError: false,
        isOrganizationError: false,
        isLocationError: false,
      }));
      hasValidationError = callFunc;
    } else {
      setError((prev) => ({
        ...prev,
        isNameError: false,
        isOrganizationError: false,
        isLocationError: false,
      }));
      hasValidationError = callFunc
    }

    if (hasValidationError) {
      // Handle validation errors, e.g., display error messages or prevent form submission
      setCoordinatorError("Please fill in all required fields.");
      return;
    } else {
      const coordinators = [];
      formRows?.forEach((row) => {
        const coordinator = {
          salutation: row?.salutation,
          username: row?.username,
          gender: row?.gender,
          mobile: row?.mobile,
          email: row?.email,
        };
        coordinators?.push(coordinator);
      });
      if (AllTextFieldState?.is_update === false) {
        const sendOrgDetails = {
          programName: AllTextFieldState?.programName,
          startDateTime: AllTextFieldState?.startDate,
          endDateTime: AllTextFieldState?.endDate,
          address: {
            text: "",
            postalCode: AllTextFieldState?.postalCode,
            latitude: AllTextFieldState?.latitude,
            longitude: AllTextFieldState?.longitude,
            district: AllTextFieldState?.district?.name,
            city: AllTextFieldState?.city,
            state: AllTextFieldState?.state?.name,
          },
          coordinators: coordinators,
          locationIds: AllTextFieldState?.locations?.map((item) => item.id),
          organizationIds: AllTextFieldState?.organizations?.map(
            (item) => item.id
          ),
          description: AllTextFieldState.description,
          isDeleted: false,
          extension: {
            additionalProp1: "",

          },
        };
        const res = await createUpdateProgram(sendOrgDetails);

        if (res?.data?.programId) {
          toasterFunction("Program Name Created successfully", "success");
        } else {
          toasterFunction(res?.error?.data?.message, "error");
        }
      }
      else {
        const sendOrgDetails = {
          programName: AllTextFieldState?.programName,
          programId: AllTextFieldState?.programId,
          startDateTime: AllTextFieldState?.startDate,
          endDateTime: AllTextFieldState?.endDate,
          address: {
            text: "",
            postalCode: AllTextFieldState?.postalCode,
            latitude: AllTextFieldState?.latitude,
            longitude: AllTextFieldState?.longitude,
            district: AllTextFieldState?.district?.name,
            city: AllTextFieldState?.city,
            state: AllTextFieldState?.state?.name,
          },
          coordinators: coordinators,
          locationIds: AllTextFieldState?.locations?.map((item) => item.id),
          organizationIds: AllTextFieldState?.organizations?.map(
            (item) => item.id
          ),
          description: AllTextFieldState.description,
          isDeleted: AllTextFieldState.isDeleted,
          extension: {
            additionalProp1: "",

          },
        };
        const res = await createUpdateProgram(sendOrgDetails);

        if (res?.data?.programId) {
          toasterFunction("Program Name Updated successfully", "success");
        } else {
          toasterFunction(res?.error?.data?.message, "error");
        }
      }
      programListData("");
      hideForm("");
    }
  };

  const handleOrgDropDownSearch = (e, value) => {
    callListOrgDropdownData(value);
  };
  const callListOrgDropdownData = async (value) => {
    const payload = {
      organizationName: value,
      partOf: "",
      state: "",
      city: "",
      status: "Active",
      page: 0,
      size: 100,
    };
    const res = await getOrganizationList(payload);
    if (res?.data?.content) setActiveOrganisation(res);
  };
  const callListLocDropdownData = async (value) => {
    const payload = {
      locName: value,
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 100,
    };
    const res = await getLocationList(payload);
    if (res?.data?.content) setActiveLocation(res);
  };

  const handleLocDropDownSearch = (e, value) => {
    callListLocDropdownData(value);
  };
  const getLocationsContent = (content, values) => {
    let finalData = content;
    values?.forEach((selectedLocation) => {
      const data = finalData?.filter(
        (activeLocation) => activeLocation.id !== selectedLocation.id
      );
      finalData = data;
    });
    return finalData;
  };
  useEffect(() => {
    setCurrentPage(1);
    callListOrgDropdownData("");
    callListLocDropdownData("");
    getStateValFun("");
    getDistrictValFun("");
    getDistrictValFun1("");
    programListData();
    // programsearchdata();
  }, []);

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Administration"
          thirdtab="Program Name"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.useradd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
              </Grid>
              <>
                <ProgramNameList
                  programList={programList}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  showFormActions={showFormActions}
                  setTextFieldState={setTextFieldState}
                  tagsview={access?.tagsview}
                  tagsupdate={access?.tagsupdate}
                  calculateData={calculateData}
                  currentPage={currentPage}
                  programListDataPage={programListDataPage}
                  programListData={programListData}
                  handleDelete={handleDelete}
                  handleRowClick={handleRowClick}
                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={hideForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.status === "INACTIVE"
                            ? "Are you sure you want to reactivate  the program ?"
                            : "Are you sure you want to Deactivate the program ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {AllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the program and user will be able to access it across all products."
                          : "This activity disable the program and user will not able to access it. You can reactivate the program at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={hideForm}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead  flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Program Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={SubmitHandler}>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Program Name<span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          error={isError?.isNameError === true}
                          helperText={
                            isError?.isNameError
                              ? "Valid (ProgramName) Required !"
                              : ""
                          }
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="programName"
                          value={AllTextFieldState?.programName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Start Date
                        </h3>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            value={
                              AllTextFieldState?.startDate
                                ? moment(AllTextFieldState?.startDate)
                                : null
                            }
                            onChange={(newValue) => {
                              setTextFieldState((prev) => ({
                                ...prev,
                                startDate: newValue ? newValue.toISOString() : null,
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "black!important",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#1976d2!important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#1976d2!important",
                                    },
                                  },
                                }}
                              />
                            )}
                            variant="standard"
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          End Date
                        </h3>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            value={
                              AllTextFieldState?.endDate
                                ? moment(AllTextFieldState?.endDate)
                                : null
                            }
                            onChange={(newValue) => {
                              setTextFieldState((prev) => ({
                                ...prev,
                                endDate: newValue ? newValue.toISOString() : null,
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "black!important",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#1976d2!important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#1976d2!important",
                                    },
                                  },
                                }}
                              />
                            )}
                            variant="standard"
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Organization Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={AllTextFieldState.organizations}
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("orgname", v)
                          }
                          onInputChange={(e, v, value) =>
                            handleOrgDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="org-list-option"
                          options={
                            activeOrganisation?.data?.content.length > 0
                              ? activeOrganisation?.data?.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              error={isError?.isOrganizationError === true}
                              helperText={
                                isError?.isOrganizationError
                                  ? "Valid (organizations) Required !"
                                  : ""
                              }
                              data-testid="organizations"
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Location Name<span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.locations &&
                            AllTextFieldState.locations
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("locname", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handleLocDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            activeLocations &&
                              activeLocations?.data &&
                              activeLocations?.data?.content &&
                              activeLocations?.data?.content?.length > 0
                              ? activeLocations?.data?.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              error={isError?.isLocationError === true}
                              helperText={
                                isError?.isLocationError
                                  ? "Valid (locations) Required !"
                                  : ""
                              }
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="color"
                        >
                          State
                        </h3>
                        <Autocomplete
                          data-testid="state"
                          value={
                            AllTextFieldState?.state
                          }
                          autoHighlight={true}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onChange={(e, v) => handleDropDownChange("state", v)}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="state-list-option"
                          options={data1 && data1?.data ? data1?.data : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="color"
                        >
                          District
                        </h3>
                        <Autocomplete
                          data-testid="district"
                          value={AllTextFieldState && AllTextFieldState?.district}
                          autoHighlight={true}
                          onChange={(e, v) => handleDropDownChange("district", v)}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={
                            districtlist && districtlist?.data
                              ? districtlist?.data
                              : []
                          }
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          City
                        </h3>
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="city"
                          value={AllTextFieldState?.city}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgPostalCode ">
                        <h3 className="font-normal text-o-form-label-color">
                          Postal Code
                        </h3>
                        <TextField
                          data-testid="postalCode"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          pattern="[0-9]*"
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="postalCode"
                          value={AllTextFieldState?.postalCode}
                          style={{ width: "360px" }}
                          id="postalCode"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Latitude
                        </h3>
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="latitude"
                          value={AllTextFieldState?.latitude}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Longitude
                        </h3>
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="longitude"
                          value={AllTextFieldState?.longitude}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div>
                        {formRows.map((rowData, rowIndex) => (
                          <div key={rowIndex}>
                            <div className="mt-5 pl-5 OrgNameContainer">
                              <h3 className="font-normal text-o-form-label-color">
                                Coordinator
                                <span style={{ color: "red" }}> *</span>
                              </h3>
                            </div>

                            <div className="mt-5 pl-5 OrgNameContainer">
                              <h3 className="font-normal text-o-form-label-color">
                                Salutation<span style={{ color: "red" }}> *</span>
                              </h3>
                              <Autocomplete
                                value={rowData?.salutation}
                                autoHighlight={true}
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={(e, newValue) =>
                                  handleSalutationChange(e, rowIndex, newValue)
                                }
                                getOptionLabel={(option) =>
                                  option ? option : ""
                                }
                                id={`salutation-${rowIndex}`}
                                options={salutationlist ? salutationlist : []}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    sx={{ width: 358 }}
                                    {...params}
                                    name="salutation"
                                    error={rowData?.isSalutationError === true}
                                    helperText={
                                      rowData.isSalutationError
                                        ? "Valid (salutation) Required !"
                                        : ""
                                    }
                                    label=" "
                                  />
                                )}
                              />
                            </div>
                            <div className="mt-5 pl-5 OrgNameContainer">
                              <h3
                                className="font-normal text-o-form-label-color"
                                id="Org"
                              >
                                Name<span style={{ color: "red" }}> *</span>
                              </h3>
                              <TextField
                                data-testid={`name-${rowIndex}`}
                                InputProps={
                                  {
                                    // "data-testid": "orgname"
                                  }
                                }
                                onFocus={resetErrorHandler}
                                label=""
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                autoComplete="OFF"
                                onChange={(e) => handleInputChange(e, rowIndex)}
                                name="username"
                                value={rowData.username}
                                error={rowData?.isNameError === true}
                                helperText={
                                  rowData.isNameError
                                    ? "Valid (Name) Required !"
                                    : ""
                                }
                                style={{ width: "360px" }}
                                id={`name-${rowIndex}`}
                                variant="standard"
                              />
                            </div>
                            <div className="mt-5 pl-5 OrgNameContainer">
                              <h3 className="font-normal text-o-form-label-color">
                                Gender<span style={{ color: "red" }}> *</span>
                              </h3>
                              <Autocomplete
                                value={rowData?.gender}
                                autoHighlight={true}
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={(e, newValue) =>
                                  handleGenderChange(e, rowIndex, newValue)
                                }
                                getOptionLabel={(option) =>
                                  option ? option : ""
                                }
                                id={`gender-${rowIndex}`}
                                options={genderlist ? genderlist : []}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    data-testid={`gender-${rowIndex}`}
                                    variant="standard"
                                    sx={{ width: 358 }}
                                    error={rowData?.isGenderError === true}
                                    helperText={
                                      rowData.isGenderError
                                        ? "Valid (Gender) Required !"
                                        : ""
                                    }
                                    {...params}
                                    name="gender"
                                    label=" "
                                  />
                                )}
                              />
                            </div>

                            <div className="mt-5 pl-5 OrgNameContainer">
                              <h3
                                className="font-normal text-o-form-label-color"
                                id="COLOR"
                              >
                                Mobile Number<span style={{ color: "red" }}> *</span>
                              </h3>
                              <TextField
                                pattern="[0-9]*"
                                data-testid={`mobile-${rowIndex}`}
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onFocus={resetErrorHandler}
                                autoComplete="OFF"
                                onChange={(e) => handleInputChange(e, rowIndex)}
                                name="mobile"
                                value={rowData?.mobile}
                                style={{ width: "360px" }}
                                id={`mobile-${rowIndex}`}
                                label=""
                                variant="standard"
                                error={rowData?.isMobileError === true}
                                helperText={
                                  rowData.isMobileError
                                    ? "Valid (Mobile) Required !"
                                    : ""
                                }
                              />
                            </div>

                            <div className="mt-5 pl-5 OrgNameContainer">
                              <h3 className="font-normal text-o-form-label-color">
                                Email ID<span style={{ color: "red" }}> *</span>
                              </h3>
                              <TextField
                                data-testid={`email-${rowIndex}`}
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                variant="standard"
                                onFocus={resetErrorHandler}
                                autoComplete="OFF"
                                onChange={(e) => handleInputChange(e, rowIndex)}
                                name="email"
                                value={rowData?.email}
                                style={{ width: "360px" }}
                                id={`email-${rowIndex}`}
                                label=""
                                error={rowData?.isEmailError === true}
                                helperText={
                                  rowData.isEmailError
                                    ? "Valid (Email) Required !"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        ))}

                        <div className="mt-5 iconadd" onClick={handleAddRow}>
                          <AddCircleOutlineIcon
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              color: "#0F4391",
                              marginBottom: "50px",
                            }}
                          />
                        </div>
                        {coordinatorError && (
                          <div
                            className="custom-field-error"
                            style={{
                              color: "#d32f2f",
                              marginLeft: "20px",
                              marginTop: "15px",
                              fontFamily: "MontserratRegular",
                              fontWeight: "400",
                              fontSize: "0.75rem",
                            }}
                          >
                            Please fill in all required fields.
                          </div>
                        )}
                      </div>
                      <div className=" pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color m-r-f"
                          id="color"
                        >
                          Description
                        </h3>
                        <TextField
                          rows={3}
                          multiline
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="description"
                          value={AllTextFieldState.description}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="outlined"
                        />
                      </div>

                      {AllTextFieldState.is_update === true ? (
                        <>
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Created By
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={fullName}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                            <h3 className=" mt-5 font-normal text-o-form-label-color">
                              Last Modified Date & Time
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={
                                  AllTextFieldState.lastModifiedTime
                                }
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                AllTextFieldState?.isDeleted ? "INACTIVE" : "ACTIVE"
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("status", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="mt-5  OrgNameContainer ">
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default ProgramName;
