import React, { useEffect, useState } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import filter from "../../../images/filter.png";
import CircularProgress from "@mui/material/CircularProgress";
import "../Organization/Mainhome.css";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import useCreatePackageHook from "./packagelogic";
import { LoggerFunction } from "../../../logger/logger";

import {
    useGetActiveLocationValueMutation,
    useGetActiveOrgValueMutation,
    usePutServicePackageMutation,
    useGetPackageDropdownMutation,
    useGetPackageListMutation,
    useUpdatePackageStatusMutation,
    useGetpatientpaginationMutation,
    useFilterPackageDataMutation,
    useFilterLocationDataMutation,
    useFilterOrganizationDataMutation,
} from "../../../services/adminLogIn";
import PackageTable from "./packageList";
import "../package/package.css";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { useSelector } from "react-redux";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 200,
    },
}));

const Package = (props) => {
    const [expand, setexpand] = useState({
        heading: "",
        icon: "",
        isSubListExpanded: false,
    });
    const [expand1, setexpand1] = useState({
        empty: true,
    });
    const [isFormVisible, setFormVisible] = useState({
        isOpen: false,
        isClose: true,
    });
    const [packagepageList, setpackagepageList] = useState([]);
    const [packageImport, setpackageImport] = useState();
    const [access, setAccess] = useState(
        JSON.parse(sessionStorage.getItem("access"))
    );

    const getpackagepageList = async (page) => {
        seIsNewData(false);
        setCurrentPage(page);
        if (isSearchFilter) {
            handleFilterData(page - 1);
        } else {
            // let payload = {}

            // payload.searchStr = ""
            // payload.page = page - 1
            // if (searchValue) {
            //   payload.searchStr = searchValue;
            // }
            let payload = {};
            payload.searchStr = "";
            payload.page = page - 1;
            if (searchValue) {
                payload.searchStr = searchValue;
            }

            setPaginationData({ ...payload });
            getPackageList(payload);
        }
    };
    const [getFilterList] = useFilterLocationDataMutation();
    const [getFilterOrgList] = useFilterOrganizationDataMutation();
    const [getActiveValFun] = useGetActiveLocationValueMutation();
    const [getOrganizationValFun] = useGetActiveOrgValueMutation();
    const [putPackageData] = usePutServicePackageMutation();
    const [getAssocReqData] = useGetPackageDropdownMutation();
    const [getPackageListData] = useGetPackageListMutation();
    const [filterResults, setFilterResults] = useState();
    const [locPriceData, setLocPriceData] = useState();
    const [getpatientpaginationfunc] = useGetpatientpaginationMutation();
    const [filterPackageData] = useFilterPackageDataMutation();
    const [locAssociatedReqData, setLocAssociatedReqData] = useState();
    const [locOrgData, setLocOrgData] = useState();
    const [locationMultiSelectOptions, setLocationMultiSelectOptions] = useState(
        []
    );
    const [updatePackageStatus] = useUpdatePackageStatusMutation();
    const [assocReqOptions, setAssocReqOptions] = useState([]);
    const [orgDataOptions, setOrgDataOptions] = useState([]);
    const [packageListData, setPackageListData] = useState([]);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const packageStatusList = ["Active", "Inactive"];
    const [openModal, setOpenModal] = useState(false);
    const [locStatus, setLocStatus] = useState("");
    const [statusVal, setStatusVal] = useState(false);
    const [packageRecordsLoading, setPackageRecordsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState();
    const filterStatusOptions = ["Active", "Inactive"];
    const [isSearchFilter, setIsSearchFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNewData, seIsNewData] = useState(true);
    const [calculateData, setTotalPageCalculate] = useState({
        number: "",
        numberOfElements: "",
        totalElements: "",
        showcount: "",
    });

    const {
        packageFormFields,
        openFilterModal,
        createPackageError,
        locpriceList,
        price,
        setErrorMessage,
        showerror,
        packageFormData,
        handleInputChange,
        resetErrorHandler,
        addRow,
        deleteRow,
        handleDropDownPriceLoc,
        getPackageTransformedData,
        handleFilterModalOpen,
        handleFilterModalClose,
        setPackageFormFields,
        setLocPriceList,
        setLocPrice,
        filterData,
        isSearchEvent,
        setIsSearchEvent,
        resetFilterData,
        handleFilterInputChange,
        handleFilterValueChange,
    } = useCreatePackageHook();

    const showFormActions = () => {
        console.log(access);
        if (access.packageadd == true) {
            setErrorMessage(false);
            setPackageFormFields((prev) => ({
                ...prev,
                isNameError: (createPackageError.isNameError = false),
            }));
            if (!isFormVisible.isOpen) {
                setFormVisible((prev) => ({
                    ...prev,
                    isOpen: (isFormVisible.isOpen = true),
                    isFormVisible,
                    isClose: (isFormVisible.isClose = false),
                }));
            }
        } else {
            toasterFunction(" you didnt have permission", "error");
        }
    };

    const hideForm = () => {
        setLocPrice([]);
        setSearchValue("");
        if (isFormVisible.isOpen) {
            setFormVisible((prev) => ({
                ...prev,
                isOpen: (isFormVisible.isOpen = false),
                isFormVisible,
                isClose: (isFormVisible.isClose = true),
            }));
            setStatusVal(false);
            setPackageFormFields({
                name: "",
                packageId: "",
                description: "",
                locAndPriceTextVal: "",
                isUpdate: false,
                externalId: [
                    {
                        id: "",
                        nameSpace: "",
                        additionalAttributes: {
                            additionalProp1: "",
                            additionalProp2: "",
                            additionalProp3: "",
                        },
                    },
                ],
                linkedEncounterNotes: [
                    {
                        encounterTypeId: "",
                        noteId: "",
                    },
                ],
                locationSpecificDetails: [],
                associatedServiceRequests: [],
                idColor: "",
                idIcon: {
                    additionalProp1: "",
                    additionalProp2: "",
                    additionalProp3: "",
                },
                supportingLocationsForTable: [],
                supportingOrganizations: [],
                supportingLocations: [],
                targetClients: [],
                status: "ACTIVE",
            });
            setPackageFormFields((prev) => ({
                ...prev,
                isNameError: (createPackageError.isNameError = false),
                locationAndPriceingError:
                    (createPackageError.locationAndPriceingError = false),
            }));
            setId("");

            setShowStatusDropdown(false);
            setErrorMessage(false);
            setLocPriceList([]);
        }
    };
    const [errorDisplay, setErrorDisplay] = useState("");

    const searchuserevent = (value) => {
        setSearchValue(value);
        getPackageList({
            page: 0,
            searchStr: value,
        });
        seIsNewData(true);
    };

    const updateLoactionOptions = (data, optionItem) => {
        if (data.length) {
            const optionArr = data.map((item) => item);
            switch (optionItem) {
                case "location": {
                    setLocationMultiSelectOptions(optionArr);
                }
                case "org": {
                    setOrgDataOptions(optionArr);
                }
                case "assocReq": {
                    setAssocReqOptions(data.map((item) => item.serviceRequestMasterId));
                }
            }
        }
    };
    const getPackageData = async () => {
        const locRes = await getActiveValFun();

        if (locRes?.data?.content) {
            updateLoactionOptions(locRes.data.content, "location");
            var log = await LoggerFunction();
            if (locRes?.data?.content)
                log.info({
                    data: "Data get successfully",
                    message: locRes.data.content,
                });
            if (locRes?.error) log.error(locRes?.error);
            setLocPriceData(locRes.data.content);
            setPackageRecordsLoading(false);
        }

        const orgRes = await getOrganizationValFun();
        if (orgRes?.data?.content) {
            updateLoactionOptions(orgRes.data.content, "org");
            var log = await LoggerFunction();
            if (orgRes?.data?.content)
                log.info({
                    data: "Data get successfully",
                    message: orgRes.data.content,
                });
            if (orgRes?.error) log.error(orgRes?.error);
            setLocOrgData(orgRes?.data?.content);
            setPackageRecordsLoading(true);
        }

        const assocServiceReqData = await getAssocReqData();
        if (assocServiceReqData?.data?.content) {
            updateLoactionOptions(assocServiceReqData.data.content, "assocReq");
            var log = await LoggerFunction();
            if (assocServiceReqData?.data?.content)
                log.info({
                    data: "Data get successfully",
                    message: assocServiceReqData.data.content,
                });
            if (assocServiceReqData?.error) log.error(assocServiceReqData?.error);
            setLocAssociatedReqData(assocServiceReqData.data.content);
            setPackageRecordsLoading(false);
        }
    };

    const submitCreatePackageForm = async () => {
        const sendData = getPackageTransformedData();
        if (sendData) {
            // const { associatedServiceRequests } = sendData;
            // let associatedReqIdArr = [];
            // for (const val of associatedServiceRequests) {
            //   const associatedItem = locAssociatedReqData.filter(
            //     (item) => item.name.trim() === val.trim()
            //   );
            //   associatedReqIdArr.push(associatedItem[0].serviceRequestMasterId);
            // }
            // sendData.associatedServiceRequests = associatedReqIdArr;
            sendData.supportingLocations = sendData.supportingLocations.map(
                (item) => item.id
            );
            sendData.supportingOrganizations = sendData.supportingOrganizations.map(
                (item) => item.id
            );
            sendData.statusVal = statusVal;
            sendData.showStatus = showStatusDropdown;
            if (packageFormFields.name === "") {
                setPackageFormFields((prev) => ({
                    ...prev,
                    isNameError: (createPackageError.isNameError = true),
                    locationAndPriceingError:
                        (createPackageError.locationAndPriceingError = true),
                }));
            } else if (
                sendData.locationSpecificDetails.length === 0 ||
                sendData.locationSpecificDetails.some(
                    (item) => !item.locationId || item.amount === ""
                )
            ) {
                setPackageFormFields((prev) => ({
                    ...prev,
                    isNameError: (createPackageError.isNameError = false),
                    locationAndPriceingError:
                        (createPackageError.locationAndPriceingError = true),
                }));
            } else {
                console.log(sendData);
                setPackageFormFields((prev) => ({
                    ...prev,
                    isNameError: (createPackageError.isNameError = false),
                    locationAndPriceingError:
                        (createPackageError.locationAndPriceingError = false),
                }));

                if (sendData.isUpdate === true) {
                    sendData["packageId"] = id;
                }
                const res = await putPackageData(sendData);
                if (res?.data?.packageId) {
                    getPackageList({ status: "", searchStr: "" });
                    setTotalPageCalculate({
                        numberOfElements: res?.data?.numberOfElements,
                        number: res?.data?.number,
                        totalElements: res?.data?.totalElements,
                    });
                    hideForm();
                } else {
                    setErrorDisplay(res?.error?.data?.message);
                    setErrorMessage(true);
                }
            }
        }
    };
    //new//
    const callListDropdownDataOrg = async (value) => {
        const payload = {
            organizationName: value,
            partOf: "",
            managingOrg: "",
            state: "",
            city: "",
            status: "",
            page: 0,
            size: 100,
        };
        const orgRes = await getFilterOrgList(payload);
        if (orgRes?.data?.content) {
            updateLoactionOptions(orgRes.data.content, "org");
            setLocOrgData(orgRes?.data?.content);
        }
    };
    const handleDropDownSearchOrg = (e, value) => {
        callListDropdownDataOrg(value);
    };

    const callListDropdownDataLoc = async (value) => {
        let payload = {
            locName: value,
            partOf: "",
            managingOrg: "",
            state: "",
            city: "",
            status: "",
            page: 0,
            size: 100,
        };
        const locRes = await getFilterList(payload);
        if (locRes?.data?.content) {
            updateLoactionOptions(locRes.data.content, "location");
            setLocPriceData(locRes.data.content);
            setPackageRecordsLoading(false);
        }
    };
    const handleDropDownSearchLoc = (e, value) => {
        callListDropdownDataLoc(value);
    };

    //
    const handleFilterCancel = () => {
        handleFilterData(0);
        handleFilterModalClose();
        // resetFilterData();
        setIsSearchFilter(false);
    };
    const handleFilterData = async (page) => {
        if (page === 0) {
            seIsNewData(true);
        }
        setIsSearchFilter(true);
        const reqBody = {
            servicePackageName: filterData.packageName,
            status:
                filterData.filterStatus === "Inactive"
                    ? "CANCELLED"
                    : filterData.filterStatus === "Active"
                        ? "ACTIVE"
                        : "",
            supportingOrganizations: filterData.supportingOrg.map((item) => item.id),
            associatedServiceRequests: filterData.associatedServiceReq,
            supportingLocations: filterData.supportingLoc.map((item) => item.id),
            page: page ? page : 0,
        };
        const res = await filterPackageData(reqBody);
        setPackageListData(res?.data?.content);
        setTotalPage(res?.data?.totalPages);
        setTotalPageCalculate({
            numberOfElements: res?.data?.numberOfElements,
            number: res?.data?.number,
            totalElements: res?.data?.totalElements,
        });
        handleFilterModalClose();
    };

    const getPackageList = async (data) => {
        // setPackageRecordsLoading(true);
        const listData = await getPackageListData(data);
        var log = await LoggerFunction();
        if (listData?.data?.content)
            log.info({
                data: "Data get successfully",
                message: listData?.data?.content,
            });
        if (listData?.error) log.error(listData?.error);

        setPackageListData(listData?.data?.content);
        setTotalPage(listData?.data?.totalPages);
        setTotalPageCalculate({
            numberOfElements: listData?.data?.numberOfElements,
            number: listData?.data?.number,
            totalElements: listData?.data?.totalElements,
        });

        // setPackageRecordsLoading(false);
    };
    const [id, setId] = useState("");
    //new//
    const [totalPage, setTotalPage] = useState(1);
    const [paginationdata, setPaginationData] = useState({
        string: "",
        page: 0,
    });

    const handleRowClick = (data) => {
        // orgDataOptions={orgDataOptions}
        // locationMultiSelectOptions={locationMultiSelectOptions}
        const {
            name,
            description,
            status,
            supportingLocations,
            supportingOrganizations,
            associatedServiceRequests,
            locationSpecificDetails,
        } = data;
        const priceObjArr = locationSpecificDetails.map((item, index) => ({
            id: index,
            value: item.amount,
        }));
        const locationPriceListArr = locationSpecificDetails.map((item) => {
            const locItem = locPriceData.filter(
                (ele) => item?.locationId === ele?.id
            );
            const returnObj = { ...item, name: locItem && locItem[0]?.name };
            return returnObj;
        });

        let loc = locationMultiSelectOptions.filter((element) =>
            data?.supportingLocations?.includes(element.id)
        );
        setPackageFormFields({
            ...packageFormFields,
            supportingLocationsForTable: loc.filter(
                (elem) =>
                    !data?.locationSpecificDetails.find(
                        ({ locationId, name }) =>
                            elem.name === name || elem.id === locationId
                    )
            ),
            supportingOrganizations: orgDataOptions.filter((element) =>
                data?.supportingOrganizations?.includes(element.name || element.id)
            ),
            supportingLocations: locationMultiSelectOptions.filter((element) =>
                data?.supportingLocations?.includes(element.name || element.id)
            ),
        });
        let associatedReqNameArr = [];
        for (const val of associatedServiceRequests) {
            if (val) {
                const associatedItem = locAssociatedReqData.filter(
                    (item) => item.serviceRequestMasterId.trim() === val.trim()
                );
                associatedReqNameArr.push(
                    associatedItem.length ? associatedItem[0].name : ""
                );
            }
        }
        const updateObj = {
            name,
            packageId: id,
            description,
            status: status === "CANCELLED" ? "Inactive" : "Active",
            associatedServiceRequests: associatedReqNameArr,
            isUpdate: true,
        };

        setLocPrice([...priceObjArr]);
        setLocPriceList([...locationPriceListArr]);
        setShowStatusDropdown(true);
        setPackageFormFields((prev) => ({
            ...prev,
            ...updateObj,
            supportingLocationsForTable: loc.filter(
                (elem) =>
                    !data?.locationSpecificDetails.find(
                        ({ locationId, name }) =>
                            elem.name === name || elem.id === locationId
                    )
            ),
            supportingOrganizations: orgDataOptions.filter((element) =>
                data?.supportingOrganizations?.includes(element.id)
            ),
            supportingLocations: locationMultiSelectOptions.filter((element) =>
                data?.supportingLocations?.includes(element.id)
            ),
        }));
        showFormActions();
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const triggerModal = (val) => {
        setOpenModal(true);
    };

    const halndleClickonProceed = () => {
        setOpenModal(false);
        setStatusVal(true);
        handleDropDownPriceLoc("status", "", locStatus);
    };

    useEffect(() => {
        getPackageData();
        getPackageList({ status: "ACTIVE", searchStr: "" });
    }, []);

    return (
        <div className="create-package">
            {packageRecordsLoading ? (
                <div style={{ position: "fixed", left: "50%", top: "50%" }}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={props.expand1.empty === true ? "main1" : "main"}>
                    <Breadcrums
                        firsttab={"Categories"}
                        secondtab="Services"
                        thirdtab="Package"
                    ></Breadcrums>
                    <ToastContainer />
                    <Grid container mt={-2}>
                        <Grid item xs={6}>
                            <div
                                className={
                                    isFormVisible.isOpen
                                        ? "leftcontainer rounded-lg"
                                        : "leftcontainer1 rounded-lg"
                                }
                            >
                                <Grid className="">
                                    <Grid className=" p-7 ">
                                        <TextField
                                            label=""
                                            onChange={(e) => searchuserevent(e.target.value)}
                                            defaultPage={0}
                                            onKeyDown={(e) => {
                                                e.key === "Enter" && e.preventDefault();
                                            }}
                                            sx={{ m: 1, width: "25ch" }}
                                            value={searchValue}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="editContainer rounded-radiusFourPix ">
                                        <Grid
                                            disabled={access?.packageadd == false}
                                            onClick={showFormActions}
                                            className={
                                                isFormVisible.isOpen ? "addIconchange" : "addIcon"
                                            }
                                        >
                                            <span className=" p-2 m-r-f ">Add</span>
                                            <AddCircleOutlineIcon
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#545454",
                                                    marginBottom: "3px",
                                                    color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"
                                                        }`,
                                                }}
                                            />
                                        </Grid>

                                        {/* <Grid className="Addaction">
                      <span className="p-2 m-r-f">Actions</span>
                      <AddCircleOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "#545454",
                          marginBottom: "3px",
                        }}
                      />
                    </Grid> */}

                                        <Grid
                                            className="addIcon package-filter-btn"
                                            onClick={handleFilterModalOpen}
                                        >
                                            <span className="p-2 m-r-f filter-text">Filter</span>
                                            <img
                                                src={filter}
                                                alt="filter.png"
                                                className="filter-icon"
                                            ></img>
                                        </Grid>
                                        <Modal
                                            open={openFilterModal}
                                            // onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box
                                                sx={style}
                                                style={{
                                                    width: "450px",
                                                    padding: "50px",
                                                    borderColor: "white",
                                                }}
                                            >
                                                <Typography
                                                    className="filtermain"
                                                    id="modal-modal-title"
                                                    variant="h6"
                                                    component="h2"
                                                    style={{
                                                        fontFamily: "MontserratRegular",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    Filter by
                                                </Typography>

                                                <div className="OrgNameContainer">
                                                    <h3
                                                        className="font-normal text-o-form-label-color"
                                                        id="Org"
                                                    >
                                                        Name
                                                    </h3>
                                                    <TextField
                                                        onFocus={(e) => resetErrorHandler(e)}
                                                        autoComplete="OFF"
                                                        onChange={(e) => handleFilterInputChange(e)}
                                                        name="packageName"
                                                        value={filterData.packageName}
                                                        style={{ width: "360px" }}
                                                        id="standard-basic"
                                                        label=""
                                                        variant="standard"
                                                    />
                                                </div>

                                                <div className="mt-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Supporting Organizations
                                                    </h3>
                                                    <Autocomplete
                                                        multiple
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        value={filterData.supportingOrg}
                                                        autoHighlight={true}
                                                        onChange={(e, v) =>
                                                            handleFilterValueChange("supportingOrg", v)
                                                        }
                                                        onInputChange={(e, value) =>
                                                            handleDropDownSearchOrg(e, value)
                                                        }
                                                        getOptionLabel={(option) =>
                                                            option ? option.name : ""
                                                        }
                                                        id="loc-list-option"
                                                        options={
                                                            orgDataOptions.length ? orgDataOptions : []
                                                        }
                                                        style={{ width: "360px" }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                sx={{ width: 358 }}
                                                                {...params}
                                                                error={createPackageError.isDropLocError}
                                                                label=" "
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className="mt-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Associated Service requests
                                                    </h3>
                                                    <Autocomplete
                                                        multiple
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        value={filterData.associatedServiceReq}
                                                        autoHighlight={true}
                                                        onChange={(e, v) =>
                                                            handleFilterValueChange("associatedServiceReq", v)
                                                        }
                                                        getOptionLabel={(option) => (option ? option : "")}
                                                        id="loc-list-option"
                                                        options={
                                                            assocReqOptions.length ? assocReqOptions : []
                                                        }
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                sx={{ width: 358 }}
                                                                {...params}
                                                                error={createPackageError.isDropLocError}
                                                                label=" "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="mt-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Supporting Locations
                                                    </h3>
                                                    <Autocomplete
                                                        multiple
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        value={filterData.supportingLoc}
                                                        autoHighlight={true}
                                                        onChange={(e, v) =>
                                                            handleFilterValueChange("supportingLoc", v)
                                                        }
                                                        onInputChange={(e, value) =>
                                                            handleDropDownSearchLoc(e, value)
                                                        }
                                                        getOptionLabel={(option) =>
                                                            option ? option.name : ""
                                                        }
                                                        id="loc-list-option"
                                                        options={
                                                            locationMultiSelectOptions.length
                                                                ? locationMultiSelectOptions
                                                                : []
                                                        }
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                sx={{ width: 358 }}
                                                                {...params}
                                                                error={createPackageError.isDropLocError}
                                                                label=" "
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className="mt-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Status
                                                    </h3>
                                                    <Autocomplete
                                                        value={filterData.filterStatus}
                                                        autoHighlight={true}
                                                        disableClearable
                                                        onChange={(e, v) =>
                                                            handleFilterValueChange("filterStatus", v)
                                                        }
                                                        getOptionLabel={(option) => (option ? option : "")}
                                                        id="org-list-option"
                                                        options={filterStatusOptions}
                                                        sx={{ width: 360 }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                onFocus={(e) => resetErrorHandler(e)}
                                                                autoComplete="OFF"
                                                                {...params}
                                                                label=""
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <Button
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={handleFilterCancel}
                                                    style={{
                                                        marginRight: "10px",
                                                        top: "30px",
                                                        borderColor: "#0F4391",
                                                        width: "99px",
                                                        height: "39px",
                                                        borderRadius: "9999px",
                                                        backgroundColor: "#E8FAFF",
                                                        color: "#0F4391",
                                                        border: "1px solid #0F4391",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    CANCEL
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={resetFilterData}
                                                    style={{
                                                        marginRight: "10px",
                                                        top: "30px",
                                                        borderColor: "#0F4391",
                                                        width: "99px",
                                                        height: "39px",
                                                        borderRadius: "9999px",
                                                        backgroundColor: "#E8FAFF",
                                                        color: "#0F4391",
                                                        border: "1px solid #0F4391",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    disableElevation
                                                    disabled={
                                                        filterData.filterStatus === "" &&
                                                        filterData.classificationId === "" &&
                                                        filterData.sampleName === ""
                                                    }
                                                    onClick={() => handleFilterData(0)}
                                                    style={{
                                                        top: "30px",
                                                        borderColor: "#0F4391",
                                                        width: "99px",
                                                        height: "39px",
                                                        borderRadius: "9999px",
                                                        backgroundColor: "#E8FAFF",
                                                        color: "#0F4391",
                                                        border: "1px solid #0F4391",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    Search
                                                </Button>
                                            </Box>
                                        </Modal>
                                    </Grid>
                                </Grid>
                                <>
                                    <PackageTable
                                        packageList={packageListData}
                                        setTotalPage={setTotalPage}
                                        totalPage={totalPage}
                                        getpackagepageList={getpackagepageList}
                                        handleRowClick={handleRowClick}
                                        setId={setId}
                                        id={id}
                                        calculateData={calculateData}
                                        currentPage={isNewData ? 1 : currentPage}
                                        packageview={access?.packageview}
                                        packageupdate={access?.packageupdate}
                                    />
                                </>
                            </div>
                        </Grid>
                        <ToastContainer />
                        {isFormVisible.isOpen ? (
                            <Grid item xs={6}>
                                <div className="rightcontainer rounded-lg">
                                    <div className="overflow-y-auto o-form-container">
                                        <>
                                            <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                                                <h1 id="COLOR">Package Overview</h1>
                                            </div>

                                            <form autoComplete="off">
                                                <div className="mt-5 pl-5 OrgNameContainer">
                                                    <h3
                                                        className="font-normal text-o-form-label-color"
                                                        id="COLOR"
                                                    >
                                                        Name <span style={{ color: "red" }}> *</span>
                                                    </h3>
                                                    <TextField
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        onFocus={(e) => resetErrorHandler(e)}
                                                        autoComplete="OFF"
                                                        error={createPackageError.isNameError === true}
                                                        helperText={
                                                            createPackageError.isNameError
                                                                ? "Valid (Name) Required !"
                                                                : ""
                                                        }
                                                        onChange={(e) => handleInputChange(e)}
                                                        name="name"
                                                        value={packageFormFields.name}
                                                        style={{ width: "360px" }}
                                                        id="standard-basic"
                                                        label=""
                                                        variant="standard"
                                                    />
                                                </div>

                                                <div className="mt-5 pl-5 OrgNameContainer">
                                                    <h3
                                                        className="font-normal text-o-form-label-color"
                                                        id="COLOR"
                                                    >
                                                        Description
                                                    </h3>
                                                    <TextField
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        multiline
                                                        rows={3}
                                                        onFocus={(e) => resetErrorHandler(e)}
                                                        autoComplete="OFF"
                                                        error={
                                                            createPackageError.isDescriptionError === true
                                                        }
                                                        helperText={
                                                            createPackageError.isDescriptionError
                                                                ? "Valid (ID) Required !"
                                                                : ""
                                                        }
                                                        onChange={(e) => handleInputChange(e)}
                                                        name="description"
                                                        value={packageFormFields.description}
                                                        style={{ width: "360px" }}
                                                        id="standard-basic"
                                                        label=""
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="mt-5 pl-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Supporting Organizations
                                                    </h3>
                                                    <Autocomplete
                                                        multiple
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        onInputChange={(e, value) =>
                                                            handleDropDownSearchOrg(e, value)
                                                        }
                                                        value={
                                                            packageFormFields &&
                                                            packageFormFields.supportingOrganizations &&
                                                            packageFormFields.supportingOrganizations
                                                        }
                                                        autoHighlight={true}
                                                        onChange={(e, v) =>
                                                            handleDropDownPriceLoc("organization", "", v)
                                                        }
                                                        getOptionLabel={(option) =>
                                                            option ? option.name : ""
                                                        }
                                                        id="loc-list-option"
                                                        options={
                                                            orgDataOptions.length ? orgDataOptions : []
                                                        }
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                sx={{ width: 358 }}
                                                                {...params}
                                                                error={createPackageError.isDropLocError}
                                                                label=" "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="mt-5 pl-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Associated Service requests
                                                    </h3>
                                                    <Autocomplete
                                                        multiple
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        value={
                                                            packageFormFields &&
                                                            packageFormFields.associatedServiceRequests &&
                                                            packageFormFields.associatedServiceRequests
                                                        }
                                                        autoHighlight={true}
                                                        onChange={(e, v) =>
                                                            handleDropDownPriceLoc("servicerequest", "", v)
                                                        }
                                                        getOptionLabel={(option) => (option ? option : "")}
                                                        id="loc-list-option"
                                                        options={
                                                            assocReqOptions.length ? assocReqOptions : []
                                                        }
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                sx={{ width: 358 }}
                                                                {...params}
                                                                error={createPackageError.isDropLocError}
                                                                label=" "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="mt-5 pl-5 OrgNameContainer ">
                                                    <h3 className="font-normal text-o-form-label-color">
                                                        Supporting Locations
                                                    </h3>
                                                    <Autocomplete
                                                        multiple
                                                        onKeyDown={(e) => {
                                                            e.key === "Enter" && e.preventDefault();
                                                        }}
                                                        onInputChange={(e, value) =>
                                                            handleDropDownSearchLoc(e, value)
                                                        }
                                                        value={
                                                            packageFormFields &&
                                                            packageFormFields.supportingLocations &&
                                                            packageFormFields.supportingLocations
                                                        }
                                                        autoHighlight={true}
                                                        onChange={(e, v) =>
                                                            handleDropDownPriceLoc("locations", "", v)
                                                        }
                                                        getOptionLabel={(option) =>
                                                            option ? option.name : ""
                                                        }
                                                        id="loc-list-option"
                                                        options={
                                                            locationMultiSelectOptions.length
                                                                ? locationMultiSelectOptions
                                                                : []
                                                        }
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                variant="standard"
                                                                sx={{ width: 358 }}
                                                                {...params}
                                                                error={createPackageError.isDropLocError}
                                                                label=" "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="mt-5 pl-5 OrgNameContainer ">
                                                    <h3 className="labelf m-s-f" id="color">
                                                        Location and Pricing
                                                        <span style={{ color: "red" }}> *</span>
                                                    </h3>
                                                </div>

                                                <div className="mt-5 pl-5 OrgNameContainer ">
                                                    <table
                                                        className="a"
                                                        style={{ width: "66%", marginRight: "130px" }}
                                                    >
                                                        <thead className="a">
                                                            <tr>
                                                                {/* <td className="a m-r-f" style={{ width: "" }}> Sr</td> */}
                                                                <td className="a m-r-f" style={{ width: "" }}>
                                                                    Location
                                                                </td>
                                                                <td className="a m-r-f" style={{ width: "" }}>
                                                                    Pricing
                                                                </td>
                                                                <td className="a m-r-f" style={{ width: "" }}>
                                                                    Action
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {locpriceList &&
                                                                locpriceList.length > 0 &&
                                                                locpriceList.map((item, i) => {
                                                                    return (
                                                                        <tr key={i} className="tablebor a">
                                                                            <td className=" m-r-f">
                                                                                <Autocomplete
                                                                                    onKeyDown={(e) => {
                                                                                        e.key === "Enter" &&
                                                                                            e.preventDefault();
                                                                                    }}
                                                                                    value={item}
                                                                                    autoHighlight={true}
                                                                                    onChange={(e, v) =>
                                                                                        handleDropDownPriceLoc("loc", i, v)
                                                                                    }
                                                                                    getOptionLabel={(option) =>
                                                                                        option ? option.name : ""
                                                                                    }
                                                                                    id="org-list-option"
                                                                                    options={
                                                                                        packageFormFields.supportingLocationsForTable
                                                                                            ? packageFormFields.supportingLocationsForTable
                                                                                            : []
                                                                                    }
                                                                                    sx={{ width: 150 }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            variant="standard"
                                                                                            {...params}
                                                                                            label=""
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                className=" m-r-f"
                                                                                style={{ width: "", paddingLeft: "" }}
                                                                            >
                                                                                <TextField
                                                                                    onFocus={(e) => resetErrorHandler(e)}
                                                                                    onKeyDown={(e) => {
                                                                                        e.key === "Enter" &&
                                                                                            e.preventDefault();
                                                                                    }}
                                                                                    autoComplete="OFF"
                                                                                    onChange={(e) =>
                                                                                        handleInputChange(e, i)
                                                                                    }
                                                                                    name="price"
                                                                                    value={
                                                                                        price && price[i] && price[i].value
                                                                                    }
                                                                                    style={{ width: "150px" }}
                                                                                    id="standard-basic"
                                                                                    label=""
                                                                                    variant="standard"
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                style={{ width: "" }}
                                                                                onKeyDown={(e) => {
                                                                                    e.key === "Enter" &&
                                                                                        e.preventDefault();
                                                                                }}
                                                                                onClick={() => deleteRow(i)}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Button
                                                    className="m-2"
                                                    onKeyDown={(e) => {
                                                        e.key === "Enter" && e.preventDefault();
                                                    }}
                                                    onClick={(e, v) => addRow("loc")}
                                                    variant="contained"
                                                    disableElevation
                                                    style={{
                                                        marginRight: "10px",
                                                        borderColor: "#0F4391",
                                                        width: "43px",
                                                        height: "22px",
                                                        borderRadius: "30px",
                                                        backgroundColor: "#E8FAFF",
                                                        color: "#0F4391",
                                                        border: "1px solid #0F4391",
                                                        margin: "14px",
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                                {createPackageError.locationAndPriceingError ? (
                                                    <div
                                                        className="locationPricingError"
                                                        style={{
                                                            color: "#d32f2f",
                                                            marginLeft: "20px",
                                                            fontSize: "0.75rem",
                                                        }}
                                                    >
                                                        Location and pricing required.
                                                    </div>
                                                ) : null}
                                                {showStatusDropdown ? (
                                                    <div className="mt-5 pl-5 OrgNameContainer">
                                                        <h3 className="font-normal text-o-form-label-color">
                                                            Status <span style={{ color: "red" }}> *</span>
                                                        </h3>
                                                        <Autocomplete
                                                            onKeyDown={(e) => {
                                                                e.key === "Enter" && e.preventDefault();
                                                            }}
                                                            value={
                                                                packageFormFields &&
                                                                packageFormFields.status &&
                                                                packageFormFields.status
                                                            }
                                                            autoHighlight={true}
                                                            onChange={(e, v) => {
                                                                setLocStatus(v);
                                                                triggerModal(v);
                                                            }}
                                                            getOptionLabel={(option) =>
                                                                option ? option : ""
                                                            }
                                                            id="loc-list-option"
                                                            options={
                                                                packageStatusList ? packageStatusList : []
                                                            }
                                                            sx={{ width: 300 }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    variant="standard"
                                                                    sx={{ width: 358 }}
                                                                    {...params}
                                                                    error={
                                                                        createPackageError.isStatusError === true
                                                                    }
                                                                    label=" "
                                                                    helperText={
                                                                        createPackageError.isStatusError
                                                                            ? "Required !"
                                                                            : ""
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                ) : null}

                                                <div className="mt-5 pl-5 OrgNameContainer ">
                                                    <div className="progressContainer mt-5 flex justify-center items-center">
                                                        {showerror === true ? (
                                                            <Alert severity="error" color="warning">
                                                                {errorDisplay}
                                                            </Alert>
                                                        ) : (
                                                            // (createOrgRes.isLoading || updateOrgRes.isLoading) ?
                                                            // <CircularProgress /> :
                                                            ""
                                                        )}
                                                    </div>
                                                    <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                                                        <Button
                                                            onClick={hideForm}
                                                            type="submit"
                                                            variant="contained"
                                                            disableElevation
                                                            style={{
                                                                marginRight: "10px",
                                                                borderColor: "#0F4391",
                                                                width: "99px",
                                                                height: "39px",
                                                                borderRadius: "9999px",
                                                                backgroundColor: "#E8FAFF",
                                                                color: "#0F4391",
                                                                border: "1px solid #0F4391",
                                                                marginBottom: "100px",
                                                            }}
                                                        >
                                                            CANCEL
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            disableElevation
                                                            onClick={submitCreatePackageForm}
                                                            style={{
                                                                borderColor: "#0F4391",
                                                                width: "99px",
                                                                height: "39px",
                                                                borderRadius: "9999px",
                                                                backgroundColor: "#E8FAFF",
                                                                color: "#0F4391",
                                                                border: "1px solid #0F4391",
                                                                marginBottom: "100px",
                                                            }}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                            <Modal
                                                open={openModal}
                                                onClose={handleModalClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <Typography
                                                        id="modal-modal-title"
                                                        variant="h6"
                                                        component="h2"
                                                    >
                                                        <Alert severity="info">
                                                            {locStatus === "Active"
                                                                ? "Are you sure you want to reactivate  the package ?"
                                                                : "Are you sure you want to Deactivate the package ?"}
                                                        </Alert>
                                                    </Typography>
                                                    <Typography
                                                        id="modal-modal-description"
                                                        sx={{ mt: 2 }}
                                                    >
                                                        {locStatus === "Active"
                                                            ? "This activity will enable the package and user will be able to access it across all products."
                                                            : "This activity disable the package and user will not able to access it. You can reactivate the package at anytime?"}

                                                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                                                            <Button
                                                                type="submit"
                                                                onClick={handleModalClose}
                                                                variant="contained"
                                                                disableElevation
                                                                style={{
                                                                    marginRight: "10px",
                                                                    borderColor: "#0F4391",
                                                                    width: "99px",
                                                                    height: "39px",
                                                                    borderRadius: "9999px",
                                                                    backgroundColor: "#E8FAFF",
                                                                    color: "#0F4391",
                                                                    border: "1px solid #0F4391",
                                                                    marginBottom: "100px",
                                                                }}
                                                            >
                                                                CANCEL
                                                            </Button>

                                                            <Button
                                                                type="submit"
                                                                onClick={halndleClickonProceed}
                                                                variant="contained"
                                                                disableElevation
                                                                style={{
                                                                    borderColor: "#0F4391",
                                                                    width: "99px",
                                                                    height: "39px",
                                                                    borderRadius: "9999px",
                                                                    backgroundColor: "#E8FAFF",
                                                                    color: "#0F4391",
                                                                    border: "1px solid #0F4391",
                                                                    marginBottom: "100px",
                                                                }}
                                                            >
                                                                PROCEED
                                                            </Button>
                                                        </div>
                                                    </Typography>
                                                </Box>
                                            </Modal>
                                        </>
                                    </div>
                                </div>
                            </Grid>
                        ) : null}
                    </Grid>
                </div>
            )}
            <AdminCookieConsentBanner />
        </div>
    );
};

export default Package;
